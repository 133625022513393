import { formatDateTime } from '../../shared/components/formatDate';
import { Button, Space, Tooltip, Typography } from 'antd';
import ClosedInstallationsButton from './ClosedInstallationsButton';
import { DeploymentPlanReportDownloadButton } from './DeploymentPlanReportDownloadButton';
import { MarkdownRenderer } from '../../../contexts/shared/components/MarkdownRenderer';
import { useState } from 'react';
import { FileTextOutlined } from '@ant-design/icons';
import { ScrollDialog } from '../../../contexts/layout/components/ScrollDialog';
import { DeploymentPlanMeta, Project } from '../../../api';
import { getReleaseName } from '../helper/extractor';

const ClosedDeploymentColumnItem = (props: {
  meta: DeploymentPlanMeta;
  project: Project;
  envId: string;
  property: 'deploymentName' | 'creator' | 'creationDate' | 'closedDate' | 'projectSoftwareRelease' | 'installations' | 'notes';
}) => {
  const [shouldShowReleaseNotes, setShouldShowReleaseNotes] = useState(false);
  let label: string | JSX.Element = 'unknown';

  switch (props.property) {
    case 'deploymentName':
      label = props.meta.name ?? 'unknown';
      break;

    case 'creator':
      const creatorLabelText = props.meta.createdByName ?? 'unknown';
      label = <Typography.Text>{creatorLabelText}</Typography.Text>;
      break;

    case 'creationDate':
      label = formatDateTime(new Date(props.meta.createdAt));
      break;

    case 'closedDate':
      label = formatDateTime(new Date(props.meta.updatedAt));
      break;

    case 'installations':
      label = <ClosedInstallationsButton planName={props.meta.name} envId={props.envId} project={props.project} planId={props.meta.id} />;
      break;

    case 'projectSoftwareRelease':
      label = <Typography.Text style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{getReleaseName(props.meta)}</Typography.Text>;
      break;

    case 'notes':
      label = (
        <>
          <ScrollDialog
            styles={{ body: {
              minHeight: '50vh'
            }}}
            open={shouldShowReleaseNotes}
            onCancel={() => {
              setShouldShowReleaseNotes(false);
            }}
            title="Description"
            footer={null}
          >
            <MarkdownRenderer>{props.meta.description || 'N/A'}</MarkdownRenderer>
          </ScrollDialog>

          <Space>
            <DeploymentPlanReportDownloadButton type="icon" deploymentPlan={props.meta} envId={props.envId} projectId={props.project.idProject} />

            <Tooltip title="Description">
              <Button 
                disabled={!props.meta.description} 
                type="text" 
                onClick={() => setShouldShowReleaseNotes(true)}
                icon={<FileTextOutlined />}
              />
            </Tooltip>
          </Space>
        </>
      );
      break;

    default:
      label = 'unknown';
      break;
  }

  return label;
};

export default ClosedDeploymentColumnItem;
