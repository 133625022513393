import { lazy, Suspense } from 'react';
import { CenteredLayout } from '../../layout/components/CenteredLayout';
import { useParams } from 'react-router-dom';
import { useProject } from '../hooks';
import { aisForceConfig } from '@p-apps/ais-force/config';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { PageLoading } from '@pacts-plugin-api';
// TODO: use plugin api
import { useInAppNavigate } from '../../../contexts/navigation/hooks/useInAppNavigate';
const AISForce = lazy(() => import('@p-apps/ais-force'));

type ProjectParams = {
  projectId: string;
};

export const ProjectAISForce = () => {
  const params = useParams() as ProjectParams;
  const project = useProject(params.projectId);
  const navigate = useInAppNavigate();

  return (
    <Suspense fallback={<PageLoading />}>
      <CenteredLayout
        wide
        title={
          <span>
            {project.data?.name || ''} <small>{aisForceConfig.name}</small>
          </span>
        }
        extra={[
          <Button icon={<ArrowLeftOutlined />} key="back" disabled={!project.data} onClick={() => navigate(-1)}>
            Back
          </Button>
        ]}
      >
        <AISForce />
      </CenteredLayout>
    </Suspense>
  );
};
