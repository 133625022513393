import { useState, useRef } from 'react';
import { Button, Divider, Form, Input, Select, Space, Spin, Tooltip, Typography } from 'antd';
import { ShareAltOutlined } from '@ant-design/icons';
import { StyledModal } from '../../../contexts/shared/components/StyledModal';
import { Document, RevisionMeta, SourceMeta } from '../../../api/report/domain/types';
import { useCurrentUser, usePermissions } from '../../session';
import { Comparator } from '../../../domain';
import { documentId, reportLink } from '../shared/util';
import { MarkdownFormItem } from '../../shared/components/MarkdownFormItem';
import useUsers from '../../administration/hooks/useUsers';
import { useSendNotification } from '../../notifications/hooks/useNotifications';
import useSubmitMetric from '../../../contexts/metrics/hooks/useMetrics';
import { Project } from '../../../api/engineering/domain/types';
import { MetricClickIds } from '../../../contexts/metrics/constants';

type ShareReportFormContent = { content: string; target: string };
const initial: ShareReportFormContent = { content: '', target: '' };

const sanitizeSearchString = (value: string) => {
  return value.toLowerCase().replace(/[\W_]+/g, '');
};
const nameFilterComparison = (title: string, input: string): boolean => {
  return title.includes(sanitizeSearchString(input));
};

export const ShareReportButton = (props: { project: Project; source: SourceMeta; revision: RevisionMeta; document: Document }) => {
  const permissions = usePermissions({ projectId: props.project.idProject.toString() });
  const currentUser = useCurrentUser();
  const send = useSendNotification();
  const users = useUsers();
  const selectRef = useRef<HTMLSelectElement>(null);
  const metrics = useSubmitMetric();

  const [visible, setVisible] = useState(false);

  const hasPermissions = permissions.reportSvc$getReportSources;

  const filteredUsers = (users.data || []).filter((u) => !u.functionalAccount).sort((a, b) => Comparator.lexicographicalComparison(a.name, b.name));

  const [form] = Form.useForm<ShareReportFormContent>();

  // TODO: use correct id
  const protocol = location.protocol;
  const host = window.location.host;
  const id = documentId(props.document);
  const link = `${protocol}//${host}${reportLink({
    projectId: props.project.idProject.toString(),
    reportId: id,
    revision: props.revision.revision.toString(),
    sourceId: props.source.id,
    mode: 'web'
  })}`;

  const [formContent, setFormContent] = useState<ShareReportFormContent>(initial);
  const formUpdated = () => {
    const newFormContent = form.getFieldsValue();

    if (formContent.target !== newFormContent.target) {
      selectRef?.current?.blur();
    }

    setFormContent(newFormContent);
  };
  const reset = () => {
    form.resetFields();
    formUpdated();
  };

  const submit = () => {
    const title = `${currentUser.name} shared a report with you.`;
    const content = `
${formContent.content ? formContent.content + '\n' : ''}
[Go to: ${props.document.meta.name}](${link})
`;

    metrics.submitClick({ operationId: MetricClickIds.reportShare }).withReportDocumentContext(props.source, props.revision, props.document, props.project);

    send
      .mutateAsync({ topic: `users/${formContent.target}/direct`, title, content })
      .then(() => {
        setVisible(false);
      })
      .catch(console.error);
  };

  const content = (
    <Space direction="vertical" style={{ width: '100%', paddingTop: 16 }}>
      <Typography.Text>Share Link</Typography.Text>
      <Input style={{ width: '100%' }} addonAfter={<Typography.Link copyable={{ text: link, icon: 'Copy' }} />} value={link} />
      <Divider />
      <Form form={form} onChange={formUpdated} initialValues={initial}>
        <Typography.Text>Send to PacTS User</Typography.Text>
        <Form.Item name="target" required>
          <Select
            ref={selectRef as any}
            optionFilterProp="label"
            placeholder="Select / Search User"
            onChange={formUpdated}
            showSearch
            optionRender={(selectedOption) => {
              const user = filteredUsers.find((usr) => usr.id === selectedOption.value)!;

              return (
                <Space key={selectedOption.value} direction="vertical" size={0}>
                  {user.name}
                  <Typography.Text type="secondary">
                    {user.email} ({user.gid})
                  </Typography.Text>
                </Space>
              );
            }}
            loading={users.isLoading}
            notFoundContent={users.isLoading ? <Spin size="small" /> : null}
            style={{ width: '100%', marginTop: 16 }}
            filterOption={(inputValue, option) => {
              const user = filteredUsers.find((usr) => usr.id === option?.value)!;
              const title = user != null ? sanitizeSearchString(`${user.name} ${user.email} ${user.gid}`) : null;

              return nameFilterComparison(title || '', inputValue || '');
            }}
            options={filteredUsers.map((user) => ({
              value: user.id,
              label: user.name
            }))}
          />
        </Form.Item>
        <Typography.Text>Write a Message</Typography.Text>
        <MarkdownFormItem optional field={['content']} shouldUpdate={(prevValues, currentValues) => prevValues.content !== currentValues.content} />
      </Form>
    </Space>
  );

  const isEnabled = formContent.target !== '';

  const dialog = (
    <StyledModal
      open={visible}
      width={600}
      footer={[
        <Button key="cancel" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="ok"
          type="primary"
          disabled={!isEnabled}
          onClick={() => {
            submit();
          }}
        >
          Share
        </Button>
      ]}
      afterClose={reset}
      title="Share Report"
      onCancel={() => {
        setVisible(false);
      }}
    >
      {content}
    </StyledModal>
  );

  if (!hasPermissions) {
    return null;
  }

  return (
    <>
      {dialog}
      <Tooltip title="Share Report">
        <Button 
          type="text" 
          onClick={() => setVisible(true)} 
          icon={<ShareAltOutlined />} 
        />
      </Tooltip>
    </>
  );
};
