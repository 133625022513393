import { Form as AntForm, FormInstance, FormProps, Input, Radio } from 'antd';
import { MarkdownFormItem } from '../../../contexts/shared/components/MarkdownFormItem';
import { Comparator, DeploymentPlanDescriptionValidator } from '../../../domain';
import { DeploymentPlanPrototype } from './DeploymentPlanForm';
import { UseQueryResult } from '@tanstack/react-query';
import { Environment } from '../../../api';
import styled from 'styled-components';

interface DeploymentDetailsFormProps {
  formItemLayout: Partial<FormProps>;
  setSubmitValue: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  redirectToFollowingStep: () => void;
  firstFormInitials: DeploymentPlanPrototype | undefined;
  firstForm: FormInstance<DeploymentPlanPrototype>;
  environments: UseQueryResult<Environment[], unknown>;
  submitValue: Record<string, any>;
}

const Form = styled(AntForm)`
  .ant-form-item-label {
    overflow: visible !important;
  }
` as typeof AntForm;

const DeploymentDetailsForm: React.FC<DeploymentDetailsFormProps> = ({
  formItemLayout,
  setSubmitValue,
  redirectToFollowingStep,
  firstFormInitials,
  firstForm,
  environments,
  submitValue
}) => {
  return (
    <Form
      {...formItemLayout}
      labelAlign="left"
      colon={false}
      name="basic"
      style={{
        marginRight: 15
      }}
      onFinish={async (value: Record<string, any>) => {
        setSubmitValue((prev) => ({
          ...prev,
          ...value
        }));

        redirectToFollowingStep();
      }}
      initialValues={{ ...firstFormInitials }}
      form={firstForm}
    >
      {/* TODO: validate validation rules */}
      {environments.isSuccess && environments.data.length > 0 && (
        <>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Please select an environment'
              }
            ]}
            style={{ marginBottom: '50px' }}
            required
            label="Environment"
            name="envId"
          >
            <Radio.Group
              onChange={(e) => {
                const newValue = e.target.value;

                firstForm.setFieldValue('envId', newValue);
                firstForm.validateFields();
                setSubmitValue((prev) => ({
                  ...prev,
                  envId: e.target.value
                }));
              }}
              style={{ marginBottom: '5px' }}
              defaultValue={submitValue?.envId || undefined}
              optionType="button"
              buttonStyle="solid"
            >
              {environments.data
                .sort((a, b) => Comparator.lexicographicalComparison(a.name, b.name))
                .map((env) => (
                  <Radio.Button value={env.id}>{env.name}</Radio.Button>
                ))}
            </Radio.Group>
            <br />
          </Form.Item>
        </>
      )}
      <Form.Item
        rules={[
          {
            required: true,
            min: 2,
            max: 256
          }
        ]}
        style={{ marginBottom: '40px' }}
        label="Name"
        required
        name={['name']}
      >
        <Input style={{ marginBottom: '5px' }} placeholder="Name" autoComplete="off" />
      </Form.Item>
      <MarkdownFormItem
        label="Description"
        field={['description']}
        rules={[DeploymentPlanDescriptionValidator.rule()]}
        shouldUpdate={(prevValues, currentValues) => prevValues.description !== currentValues.description}
      />
    </Form>
  );
};

export default DeploymentDetailsForm;
