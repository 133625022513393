import { useCallback, useEffect, useMemo, useState } from 'react';
import { Skeleton } from 'antd';
import { SoftwareAppVersionComparisonResult, softwareAppVersionComparisonResultValue } from './SoftwareAppVersionComparisonResult';
import { SoftwareAppSelection } from '../../../domain';
import { useProjectSoftwareAppsScoped } from '../hooks/useProjectSoftwareapps';
import { ComparisonResult } from '../../comparison';
import { useCommonSoftwareAppsScoped } from '../hooks/useCommonSoftwareApps';
import { ScopedSoftwareApp } from '../hooks/scopedSoftwareApp';
import { Comparator } from '../../../domain/extensions/comparison';
import { Project, SoftwareApp } from '../../../api/engineering/domain/types';
import Table from '../../../contexts/shared/components/Table/Table';
import { SegmentedValue } from 'antd/es/segmented';
import Segmented from '../../../contexts/shared/components/Segmented/Segmented';
import { ColumnsType } from 'antd/es/table';
import * as Styled from './SoftwareAppsSubTable.styled';
import { MetricOnVisible } from '../../../contexts/metrics/components/MetricOnVisible';
import { MetricViewIds } from '../../../contexts/metrics/constants';
import { projectToTags } from '../../../contexts/metrics/utils/mapping';
import { useSearchParameter } from '../../../contexts/navigation/hooks/useSearchParameter';
import { MarkdownPreview } from '../../../contexts/shared/components/MarkdownPreview';
import { useLocalStorageState, useDebounce } from '../../../contexts/shared/hooks';

export type SoftwareAppsCompareListProps = {
  project?: Project;
  projectId: string;
  selectedAppsRight?: SoftwareAppSelection[];
  selectedAppsLeft?: SoftwareAppSelection[];
  selectedTitleRight: string;
  selectedTitleLeft: string;
  loading?: boolean;
};

const prefferedCategoryListKey = 'preferred-app-category-list-key';
const allCategoriesLabel = 'All Categories';

const findApp = (app: ScopedSoftwareApp, selection?: SoftwareAppSelection[]) => {
  return selection?.find((swaA) => app.idSoftwareApp === swaA.app.idSoftwareApp && app.scope === swaA.app.scope);
};

export const ProjectSoftwareAppsCompareList = (props: SoftwareAppsCompareListProps) => {
  const softwareApps = useProjectSoftwareAppsScoped(props.projectId);
  const [prefferedTabKey, setPrefferedTabKey] = useLocalStorageState<string>(prefferedCategoryListKey, undefined);
  const [activeTabKey, setActiveTabKey] = useSearchParameter('tab', prefferedTabKey || undefined);
  const commonSoftwareApps = useCommonSoftwareAppsScoped();
  const [filteredApps, setFilteredApps] = useState({} as any);

  const isLoading =
    softwareApps.isLoading ||
    commonSoftwareApps.isLoading ||
    props.loading ||
    filteredApps[activeTabKey ?? ''] == null ||
    !props.selectedAppsLeft ||
    !props.selectedAppsRight;

  // make the component more resilient to props not being set in order
  // when the left or right apps lists are set after loading=false, the default sort order does not work
  // hence debounce the loading by a few cycles to make sure the table is rendered with correct data
  // enable only for transition from true to false
  const loadingDebounced = useDebounce(isLoading, 20, true) || isLoading;
  // debounce selected apps to compensate for changes caused by hooks
  // make sure debounced time is lower than loading
  const leftDebounced = useDebounce(props.selectedAppsLeft, 10);
  const rightDebounced = useDebounce(props.selectedAppsRight, 10);

  const getCategoryName = (app: SoftwareApp): string => {
    return app.categories && app.categories.length > 0 ? app.categories[0].name : 'unknown';
  };

  const handleActiveTabChange = (key: SegmentedValue) => {
    const keyStr = key.toString();

    setActiveTabKey(keyStr);
    setPrefferedTabKey(keyStr);
  };
  const statusSorter = useCallback(
    (a: ScopedSoftwareApp, b: ScopedSoftwareApp) => {
      const aaApp = findApp(a, rightDebounced);
      const abApp = findApp(a, leftDebounced);
      const aComparisonResult = softwareAppVersionComparisonResultValue(aaApp?.version, abApp?.version);

      const baApp = findApp(b, rightDebounced);
      const bbApp = findApp(b, leftDebounced);
      const bComparisonResult = softwareAppVersionComparisonResultValue(baApp?.version, bbApp?.version);

      return aComparisonResult - bComparisonResult;
    },
    [rightDebounced, leftDebounced]
  );

  const filterApps = useCallback(() => {
    const mergedApps = [...softwareApps.data, ...commonSoftwareApps.data];
    const tabsCategories = mergedApps
      ?.filter((swa) => findApp(swa, props.selectedAppsRight) || findApp(swa, props.selectedAppsLeft))
      .reduce((rv, x) => {
        const category = getCategoryName(x);
        (rv[allCategoriesLabel] = rv[allCategoriesLabel] || []).push(x);
        (rv[category] = rv[category] || []).push(x);
        return rv;
      }, {} as any);

    Object.keys(tabsCategories).forEach((key) => {
      tabsCategories[key].sort(statusSorter).reverse();
    });

    const groupedSoftwareApps = {
      ...tabsCategories
    };
    return groupedSoftwareApps;
  }, [props.selectedAppsLeft, props.selectedAppsRight, softwareApps.data, commonSoftwareApps.data, statusSorter]);

  useEffect(() => {
    setFilteredApps(filterApps());
  }, [filterApps]);

  useEffect(() => {
    if (filteredApps[activeTabKey ?? ''] == null && Object.keys(filteredApps).length > 1) {
      const defaultKey = Object.keys(filteredApps)[0];

      setActiveTabKey(defaultKey);
    }
  }, [filteredApps, activeTabKey, setActiveTabKey]);

  const columns: ColumnsType<ScopedSoftwareApp> = useMemo(
    () => [
      {
        title: 'Name',
        key: 'name',
        width: '300px',
        sorter: (a: ScopedSoftwareApp, b: ScopedSoftwareApp) => Comparator.lexicographicalComparison(a.name, b.name),
        render: (swa: ScopedSoftwareApp) => {
          return swa.name;
        }
      },
      {
        title: 'Description',
        key: 'description',
        render: (swa: ScopedSoftwareApp) => <MarkdownPreview title="Description" content={swa.description || ''} />,
        width: 400
      },
      {
        title: 'Status',
        key: 'status',
        filters: [
          {
            text: 'Hide Equal Items',
            value: ComparisonResult.EQUAL
          }
        ],
        onFilter: (value: any, record: ScopedSoftwareApp) => {
          const aApp = findApp(record, rightDebounced);
          const bApp = findApp(record, leftDebounced);
          if (aApp?.version.idSoftwareAppVersion === bApp?.version.idSoftwareAppVersion) {
            return false;
          }
          return true;
        },
        sortDirections: ['descend'],
        sorter: statusSorter,
        render: (swa: ScopedSoftwareApp) => {
          const aApp = findApp(swa, rightDebounced);
          const bApp = findApp(swa, leftDebounced);
          return <SoftwareAppVersionComparisonResult a={aApp?.version} b={bApp?.version} />;
        },
        width: 200
      },
      {
        title: props.selectedTitleLeft,
        key: 'versionA',
        render: (swa: ScopedSoftwareApp) => {
          const bApp = findApp(swa, leftDebounced);
          return bApp?.version?.version || '--';
        },
        width: 200
      },
      {
        title: props.selectedTitleRight,
        key: 'versionb',
        render: (swa: ScopedSoftwareApp) => {
          const aApp = findApp(swa, rightDebounced);
          return aApp?.version?.version || '--';
        },
        width: 200
      }
    ],
    [props.selectedTitleLeft, props.selectedTitleRight, leftDebounced, rightDebounced, statusSorter]
  );

  if (activeTabKey == null) {
    return null;
  }

  if (loadingDebounced) {
    return <Skeleton active />;
  }

  const tabOptions = Object.keys(filteredApps);
  const sortedTabOptions = [tabOptions[0], ...tabOptions.slice(1).sort((a, b) => Comparator.lexicographicalComparison(a, b))];

  const hasSegmented = tabOptions.length > 2;
  const segmentedHeight = hasSegmented ? 54 : 0;

  return (
    <div>
      <MetricOnVisible view={MetricViewIds.appsCompareList} payload={props.project ? projectToTags(props.project) : undefined} />
      {hasSegmented ? (
        <Styled.SegmentedWrapper>
          <Segmented value={activeTabKey} options={sortedTabOptions} onChange={handleActiveTabChange} />
        </Styled.SegmentedWrapper>
      ) : null}
      <Table
        scroll={{ x: true }}
        sticky={{
          offsetHeader: 128 + 14 + segmentedHeight
        }}
        columns={columns}
        rowKey={(record: ScopedSoftwareApp) => record.idSoftwareApp?.toString() || ''}
        dataSource={filteredApps[activeTabKey] ?? []}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 20,
          pageSizeOptions: ['10', '20', '50']
        }}
      />
    </div>
  );
};
