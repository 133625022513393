import { BugOutlined, DownOutlined, GlobalOutlined, QuestionCircleOutlined, StarOutlined, TeamOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, MenuProps, Row, Tooltip, Typography } from 'antd';
import { useState } from 'react';
import { THEMES } from '../../theme/ThemeProvider';
import { UserSatisfaction } from '../../usersatisfaction';
import styled from 'styled-components';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';
import { NavLink } from 'react-router-dom';
import { Redirects } from '@pacts/utils-redirects';

const IconWrapper = styled.div`
  font-size: ${({ theme }) => theme.fontSize}px !important;
`;

const IconWrapperCol = styled(Col)<{ fontSize: number; paddingTop?: number; actualTheme: THEMES }>`
  font-size: ${({ fontSize }) => fontSize}px;
  padding-top: ${({ paddingTop }) => (paddingTop ? `${paddingTop}px` : undefined)};
  color: ${({ theme, actualTheme }) => (actualTheme === THEMES.DARK ? theme.colorWhite : undefined)};
`;

const MenuButton = styled(Button)`
  color: ${({ theme }) => theme.colorText};
  padding: 0 4px;
`;

const MenuEntryWrapper = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: ${({ theme }) => theme.fontSize};
`;

const WrappedNavLink = styled(NavLink)`
  line-height: 33px;
`;

const menuItems = [
  {
    name: 'Write us on teams',
    link: Redirects.TEAMS,
    icon: (
      <IconWrapper>
        <TeamOutlined />
      </IconWrapper>
    )
  },
  {
    name: 'Report an issue',
    link: Redirects.SERVICE_DESK,
    icon: (
      <IconWrapper>
        <BugOutlined />
      </IconWrapper>
    )
  },
  {
    name: 'PacTS documentation',
    link: Redirects.WIKI_PACTS,
    icon: (
      <IconWrapper>
        <GlobalOutlined />
      </IconWrapper>
    )
  },
  {
    name: 'Give feedback',
    icon: (
      <IconWrapper>
        <StarOutlined />
      </IconWrapper>
    )
  }
];

export const InfoMenu = (props: { theme?: THEMES }) => {
  const [showModal, setShowModal] = useState(false);
  const permissions = usePermissions();

  if (!permissions.webui$showHelp) return null;

  const actualTheme = props.theme ?? THEMES.DARK;

  const handleChange = (index: number) => {
    switch (index) {
      case 4:
        setShowModal(!showModal);
        break;
      default:
        break;
    }
  };

  const items: MenuProps['items'] = [
    {
      key: '0',
      label: <Typography.Text>Support channels</Typography.Text>,
      type: 'group'
    },
    {
      type: 'divider'
    },
    ...menuItems.map((item, i) => ({
      key: i + 1,
      label: item.link ? (
        <WrappedNavLink target="_blank" to={item.link ?? ''}>
          {item.name}
        </WrappedNavLink>
      ) : (
        <MenuEntryWrapper>{item.name}</MenuEntryWrapper>
      ),
      onClick: () => handleChange(i + 1),
      icon: item.icon
    }))
  ];

  return (
    <>
      <Tooltip title="Support channels" placement="left" trigger={['hover']}>
        <Dropdown arrow trigger={['click']} menu={{ items }} overlayStyle={{ zIndex: 100 }}>
          <MenuButton type="text">
            <Row wrap={false} gutter={4} align="middle">
              <IconWrapperCol fontSize={18} actualTheme={actualTheme}>
                <QuestionCircleOutlined />
              </IconWrapperCol>
              <IconWrapperCol fontSize={8} paddingTop={2} actualTheme={actualTheme}>
                <DownOutlined />
              </IconWrapperCol>
            </Row>
          </MenuButton>
        </Dropdown>
      </Tooltip>
      <UserSatisfaction showModal={showModal} onFinish={setShowModal} />
    </>
  );
};
