import { Space, Table, TableColumnsType, Tag, Tooltip, Typography } from 'antd';
import { RefreshTokenAuthFlowEnum, RefreshTokenClientIdEnum, RefreshTokenMetadata, RefreshTokenMetadataWithUserId } from '../../../api';
import { UserName } from '../../../contexts/administration/components/UserName';
import { useContext, useMemo } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useUsers } from '../../../contexts/administration';
import { PacTSContext } from '../../../state/store';
import { useTableFilter } from '../../../contexts/shared/components/TableFilter';
import { formatDateTime } from '../../../contexts/shared/components/formatDate';
import { ConfirmationButton } from '../../../contexts/shared/components/ConfirmationButton';
import { useTableSearch } from '../../../contexts/shared/components/TableSearch';

export const SessionTable = (props: {
  sessions: RefreshTokenMetadata[] | RefreshTokenMetadataWithUserId[];
  terminate: (session: RefreshTokenMetadata) => Promise<void>;
}) => {
  const [state] = useContext(PacTSContext);
  const users = useUsers();
  const issuedSorter = (a: RefreshTokenMetadata, b: RefreshTokenMetadata) => a.issuedAt.getTime() - b.issuedAt.getTime();
  const lastTokenSorter = (a: RefreshTokenMetadata, b: RefreshTokenMetadata) => a.lastAccessTokenIssuedAt.getTime() - b.lastAccessTokenIssuedAt.getTime();
  const expirySorter = (a: RefreshTokenMetadata, b: RefreshTokenMetadata) => a.expiry.getTime() - b.expiry.getTime();
  const limitSorter = (a: RefreshTokenMetadata, b: RefreshTokenMetadata) => a.limit.getTime() - b.limit.getTime();
  const countSorter = (a: RefreshTokenMetadata, b: RefreshTokenMetadata) => a.counter - b.counter;

  const clientFilter = useTableFilter({
    key: 'client-filter',
    onFilter: (r: RefreshTokenMetadataWithUserId, value: string) => r.clientId === value,
    values: Object.keys(RefreshTokenClientIdEnum).map((k, idx) => ({ text: k, value: Object.values(RefreshTokenClientIdEnum)[idx] })),
    searchParamId: 'client'
  });

  const flowFilter = useTableFilter({
    key: 'flow-filter',
    onFilter: (r: RefreshTokenMetadataWithUserId, value: string) => {
      const idx = Object.values(RefreshTokenAuthFlowEnum).findIndex((k) => k === value);
      if (idx < 0) return false;
      return r.authFlow === Object.keys(RefreshTokenAuthFlowEnum)[idx];
    },
    values: Object.keys(RefreshTokenAuthFlowEnum).map((k, idx) => ({ text: k, value: Object.values(RefreshTokenAuthFlowEnum)[idx] })),
    searchParamId: 'flow'
  });

  const userFilter = useTableFilter({
    key: 'user-filter',
    onFilter: (r: RefreshTokenMetadataWithUserId, value: string) => r.userid.toString() === value,
    values: (users.data || []).map((u) => ({ text: u.name, value: u.id.toString() })),
    searchParamId: 'user'
  });

  const familySearch = useTableSearch({ searchValueProvider: 'tokenFamily', searchParamId: 'family' });

  const refreshToken = useMemo(() => (state?.token?.refreshToken ? jwtDecode<{ tfi?: string }>(state.token.refreshToken) : {}), [state.token.refreshToken]);

  const columns: TableColumnsType<RefreshTokenMetadata | RefreshTokenMetadataWithUserId> = [
    {
      title: 'User Id',
      key: 'userid',
      ...userFilter,
      render: (record: RefreshTokenMetadataWithUserId) => {
        return (
          <>
            <UserName userId={record.userid} />
          </>
        );
      }
    },
    {
      title: 'Client',
      ...clientFilter,
      dataIndex: 'clientId'
    },
    {
      title: 'Auth Flow',
      ...flowFilter,
      dataIndex: 'authFlow'
    },
    {
      title: 'Counter',
      sorter: countSorter,
      dataIndex: 'counter'
    },
    {
      title: 'Issued',
      sorter: issuedSorter,
      render: (record: RefreshTokenMetadataWithUserId) => {
        return formatDateTime(record.issuedAt);
      }
    },
    {
      title: 'Current Token Expiry',
      sorter: expirySorter,
      render: (record: RefreshTokenMetadataWithUserId) => {
        return formatDateTime(record.expiry);
      }
    },
    {
      title: 'Session Expiry',
      sorter: limitSorter,
      render: (record: RefreshTokenMetadataWithUserId) => {
        return formatDateTime(record.limit);
      }
    },
    {
      title: 'Last Access Token Issued At',
      sorter: lastTokenSorter,
      defaultSortOrder: 'descend',
      render: (record: RefreshTokenMetadataWithUserId) => {
        return formatDateTime(record.lastAccessTokenIssuedAt);
      }
    },
    {
      title: 'Family',
      ...familySearch,
      render: (record: RefreshTokenMetadataWithUserId) => {
        const current = refreshToken.tfi === record.tokenFamily;
        return (
          <Space>
            <Typography.Text type={current ? 'success' : undefined} code copyable>
              {record.tokenFamily}
            </Typography.Text>
            {current ? <Tag color="green">THIS</Tag> : null}
          </Space>
        );
      }
    },
    {
      title: 'Provider',
      dataIndex: 'provider'
    },
    {
      title: 'Actions',
      render: (record: RefreshTokenMetadataWithUserId) => {
        return (
          <Tooltip title="Terminate">
            <ConfirmationButton
              description="The user will still be able to work until the current access token expires."
              buttonType="regular"
              onOk={() => {
                props.terminate(record);
              }}
            />
          </Tooltip>
        );
      }
    }
  ];

  return (
    <Table
      size="small"
      scroll={{ x: true }}
      columns={columns}
      rowKey={(record: RefreshTokenMetadata) => record.tokenFamily}
      dataSource={props.sessions}
      pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
    />
  );
};
