import { Button, Drawer, Skeleton, Space, TableColumnsType } from 'antd';
import { useMemo, useState } from 'react';
import Table from '../../../contexts/shared/components/Table/Table';
import { groupDeploymentsByComponent } from '../helper/latestComponents';
import { useDeploymentPlan } from '../hooks/useDeploymentPlan';
import { SoftwareAppVersionPreview } from '../../../contexts/softwareApps';
import { SoftwareComponentDownloadGateway } from './SoftwareComponentDownloadGateway';
import { SoftwareComponentOpenInToolmanagerGateway } from './SoftwareComponentOpenToolmanagerGateway';
import { ClosedInstallationsNestedTable } from './ClosedInstallationsNestedTable';
import { Comparator } from '../../../domain';
import { DeploymentPlantTableVersionRowItem } from './DeploymentPlanTableVersionRowItem';
import { InstalledStatus } from './InstalledStatus';

import type { Project } from '../../../api';
import type { ComponentForDevices } from '../helper/deploymentTypes';
import { useTableFilter } from '../../../contexts/shared/components/TableFilter';
import { useTableSearch } from '../../../contexts/shared/components/TableSearch';

const getRowKey = (d: ComponentForDevices) => {
  return d.identifier;
};

const ClosedInstallationsButton = (props: { planId: string; planName: string; envId: string; project: Project }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const plan = useDeploymentPlan(props.planId, props.envId, props.project.idProject, { disabled: !isDrawerOpen });

  const byComponent = useMemo(() => groupDeploymentsByComponent(plan.data?.deployments ?? []), [plan.data]);

  const nameSearch = useTableSearch({ searchValueProvider: 'component.name' });

  const componentSorter = (a: ComponentForDevices, b: ComponentForDevices) => Comparator.lexicographicalComparison(a.component.name, b.component.name);

  const installationStatusSorter = (a: ComponentForDevices, b: ComponentForDevices) => {
    if (a.allInstalled && !b.allInstalled) return -1;
    if (b.allInstalled && !a.allInstalled) return 1;
    return 0;
  };

  const installedFilter = useTableFilter<ComponentForDevices>({
    key: 'hide-installed-filter',
    onFilter: (data, val) => {
      return val === 'hideInstalled' && !data.allInstalled;
    },
    values: [
      {
        text: 'Hide Installed',
        value: 'hideInstalled'
      }
    ]
  });

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const columns: TableColumnsType<ComponentForDevices> = [
    {
      title: 'Software Component',
      key: 'name',
      align: 'left',
      sorter: componentSorter,
      ...nameSearch,
      render: (swa: ComponentForDevices) => {
        return swa.component.name;
      }
    },
    {
      title: 'Version',
      key: 'version',
      align: 'left',
      width: 250,
      render: (swa: ComponentForDevices) => <DeploymentPlantTableVersionRowItem project={props.project} component={swa.component} />
    },
    {
      title: 'Status',
      key: 'state',
      align: 'left',
      sorter: installationStatusSorter,
      ...installedFilter,
      width: 180,
      render: (f: ComponentForDevices) => {
        return <InstalledStatus component={f} />;
      }
    },
    {
      title: 'Actions',
      key: 'operation',
      fixed: 'right',
      align: 'center',
      width: 180,
      render: (f: ComponentForDevices) => (
        <Space size={0}>
          <SoftwareComponentDownloadGateway component={f.component} projectId={props.project.idProject} />
          <SoftwareComponentOpenInToolmanagerGateway component={f.component} project={props.project} />
          <SoftwareAppVersionPreview component={f.component} projectId={props.project.idProject.toString()} />
        </Space>
      )
    }
  ];

  return (
    <>
      <Button type="primary" onClick={handleDrawerOpen}>
        Installations
      </Button>
      <Drawer open={isDrawerOpen} title={props.planName} onClose={handleDrawerClose} width={960} forceRender>
        <Skeleton active loading={!plan.isSuccess}>
          <Table<ComponentForDevices>
            size="small"
            rowKey={getRowKey}
            pagination={false}
            columns={columns}
            dataSource={byComponent}
            expandable={{
              expandedRowRender: (d) => <ClosedInstallationsNestedTable data={d} key={d.identifier + 'nested'} />,
              rowExpandable: () => true
            }}
          />
        </Skeleton>
      </Drawer>
    </>
  );
};

export default ClosedInstallationsButton;
