import { Row, Col, Form, Input, DatePicker, Card, Typography, Space, Select, Spin, Checkbox, Tag } from 'antd';

import { useState } from 'react';
import useUsers from '../../administration/hooks/useUsers';
import { useCurrentUser } from '../../session';
import { notificationMatchers } from '../domain';
import { useSendNotification } from '../hooks/useNotifications';
import { Notifications } from './Notifications';
import { SubscriptionPopup } from './SubscriptionPopup';
import dayjs from 'dayjs';
import { MarkdownFormItem } from '../../../contexts/shared/components/MarkdownFormItem';
import { ConfirmationButton } from '../../../contexts/shared/components/ConfirmationButton';
import { FullWidthSelect, FullWidthSpace } from '../../../contexts/shared/base/Components.styled';
import styled from 'styled-components';

const OptionText = styled(Typography.Text)`
  color: inherit;
`;

const initial = {
  title: '',
  content: '',
  expiry: '',
  trigger: {
    tokenRefresh: false
  },
  topic: 'system/broadcast'
};

const supportedTopics: { topic: string; description: string; title: string; channels: string[] }[] = [
  {
    topic: 'system/broadcast',
    description: 'Broadcast to all users via push channels. Users are subscribed via PacTS and ImPacTS by default and cannot unsubscribe.',
    title: 'Broadcast',
    channels: ['Push']
  },
  {
    topic: 'system/announcement',
    description: 'Announcement to all users via all channels. Users are subscribed via PacTS, ImPacTS and Email by default and cannot unsubscribe.',
    title: 'Announcement',
    channels: ['Push', 'Mail']
  },
  {
    topic: 'system/maintenance',
    description: 'Maintenance notification to all users via push channels. Users are subscribed via PacTS and ImPacTS by default and cannot unsubscribe.',
    title: 'Maintenance',
    channels: ['Push']
  },
  {
    topic: 'training/announcement',
    description: 'Training announcement. Users are subscribed via PacTS by default and can be unsubscribed.',
    title: 'Training',
    channels: ['Push']
  },
  {
    topic: 'users/<ID>/direct',
    description: 'Direct notification to user. Users are subscribed via PacTS, ImPacTS and Email by default and cannot unsubscribe.',
    title: 'Direct',
    channels: ['Push', 'Mail']
  }
];

export const SystemNotificationDashboard = () => {
  const [form] = Form.useForm<typeof initial>();
  const send = useSendNotification(true);
  const users = useUsers();
  const user = useCurrentUser();
  const [userSelectDisabled, setUserSelectDisabled] = useState(true);
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 21 }
  };

  const [selectedTopic, setSelectedTopic] = useState<(typeof supportedTopics)[0] | undefined>(supportedTopics.find((t) => t.topic === initial.topic));

  return (
    <Card
      title={<Typography.Title level={4}>Notification Dashboard</Typography.Title>}
      extra={[
        <Space key="systemsubs">
          <SubscriptionPopup
            topics={[
              { key: '**', title: 'All' },
              { key: 'system/unknown', title: 'Unknown Topics' },
              { key: 'system/announcement', title: 'System Announcement' },
              { key: 'training/announcement', title: 'Training Announcement' },
              { key: 'system/broadcast', title: 'Broadcast' },
              { key: 'system/maintenance', title: 'Maintenance' },
              { key: `users/${user.id}/direct`, title: 'Direct' }
            ]}
            title="All"
          />
        </Space>
      ]}
    >
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Notifications matcher={notificationMatchers} clearDisabled title="System Notifications & Trainings" filter={/(system|training)\/.*/} height="70vh" />
        </Col>
        <Col span={12}>
          <Card title="Create New">
            <Form
              disabled={send.isLoading}
              form={form}
              onFieldsChange={() => {
                setUserSelectDisabled(form.getFieldValue('topic') !== 'users/<ID>/direct');
                setSelectedTopic(supportedTopics.find((t) => t.topic === form.getFieldValue('topic')));
              }}
              {...layout}
              name="basic"
              initialValues={{ ...initial }}
              onFinish={(value) => {
                const copy = {
                  ...value,
                  expiry: value.expiry ? (value.expiry as any).toISOString() : undefined,
                  topic: `${value.topic}`
                };
                if (copy.topic === 'users/<ID>/direct') {
                  const toSend: (typeof copy)[] = [];
                  (copy as any as { targets: string[] }).targets.forEach((t) => {
                    const userMessage = { ...copy, topic: copy.topic.replace('<ID>', t) };
                    delete (userMessage as any).targets;
                    toSend.push(userMessage);
                  });
                  Promise.all(toSend.map((s) => send.mutateAsync(s)))
                    .then(() => {
                      form.resetFields();
                    })
                    .catch((e) => console.error('error sending', e));
                  return;
                }
                delete (copy as any).targets;
                send
                  .mutateAsync(copy)
                  .then(() => {
                    form.resetFields();
                  })
                  .catch((e) => console.error('error sending', e));
              }}
            >
              <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Required' }]}>
                <Input />
              </Form.Item>
              <MarkdownFormItem
                label="Content"
                optional
                field={['content']}
                shouldUpdate={(prevValues, currentValues) => prevValues.content !== currentValues.content}
              />
              <Form.Item label="Topic" name="topic" rules={[{ required: true, message: 'Required' }]}>
                <FullWidthSelect
                  optionRender={(selectedOption) => {
                    const topic = supportedTopics.find((t) => t.topic === selectedOption.value)!;
                    return (
                      <FullWidthSpace key={selectedOption.value} direction="vertical" size={0}>
                        {selectedOption.label}
                        <OptionText ellipsis={{ tooltip: topic.description }}>{topic.description}</OptionText>
                      </FullWidthSpace>
                    );
                  }}
                  options={supportedTopics.map((t) => ({
                    value: t.topic,
                    label: (
                      <Space>
                        {t.title}
                        <Space size={0}>
                          {t.channels.map((c) => (
                            <Tag color="processing">{c}</Tag>
                          ))}
                        </Space>
                      </Space>
                    )
                  }))}
                />
              </Form.Item>
              <Typography.Paragraph style={{ marginLeft: '12.5%', marginBottom: 32 }} strong>
                {selectedTopic?.description}
              </Typography.Paragraph>
              <Form.Item label="Direct Targets" name="targets" shouldUpdate>
                <Select
                  mode="multiple"
                  optionFilterProp="label"
                  disabled={userSelectDisabled}
                  placeholder="Select Direct Message Users"
                  loading={users.isLoading}
                  notFoundContent={users.isLoading ? <Spin size="small" /> : null}
                  style={{ width: '100%' }}
                  filterOption={(inputValue, option) => {
                    return !!option?.title?.toString().toLowerCase().includes(inputValue.toLowerCase());
                  }}
                >
                  {users.data?.map((d) => (
                    <Select.Option title={`${d.name} (${d.id})`} value={d.id} key={d.id} label={d.name}>
                      {`${d.name} (${d.id})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Expiry" name="expiry">
                <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }} />
              </Form.Item>
              <Form.Item name={['trigger', 'tokenRefresh']} valuePropName="checked" wrapperCol={{ offset: 3, span: 21 }}>
                <Checkbox>
                  Trigger Token Refresh
                  <Typography.Paragraph type="secondary">
                    Triggering a token refresh will force-update the access tokens of all recipients (PacTS Web, ImPacTS starting Version 2.7.5). This can be
                    used to enroll permission updates.
                  </Typography.Paragraph>
                </Checkbox>
              </Form.Item>
              <ConfirmationButton
                loading={send.isLoading}
                outlined
                title="Are you sure? Sending cannot be undone!"
                okText="Send"
                onOk={() => {
                  form.submit();
                }}
                caption="Send"
              />
            </Form>
          </Card>
        </Col>
      </Row>
    </Card>
  );
};
