import { Redirect } from './Redirect';
import { useSearchParameter } from '../navigation/hooks';
import { NotFoundForwarder } from '../../routing/NotFoundForwarder';
import { RedirectKeys, Redirects } from '@pacts/utils-redirects';
import { LINKS } from '../../constants/links';

const associations: { [key in RedirectKeys]: string } = {
  SERVICE_DESK: LINKS.LINK_SERVICE_DESK,
  WIKI_PACTS: LINKS.LINK_PACTS_WIKI,
  WIKI_IMPACTS: LINKS.LINK_IMPACTS_WIKI,
  WIKI_PACTS_DEPLOYMENT_PLAN: LINKS.LINK_PACTS_WIKI_CREATE_DEPLOYMENT,
  WIKI_PACTS_RELEASE_NOTES: LINKS.LINK_PACTS_WIKI_HOW_TO_RELEASE_NOTES,
  WIKI_PACTS_TOKENS: LINKS.LINK_PACTS_WIKI_TOKEN_GENERATION,
  WIKI_PACTS_INTEGRITY: LINKS.LINK_PACTS_WIKI_CHECKSUM,
  TEAMS: LINKS.LINK_TEAMS,
  SE_IMPRINT: LINKS.LINK_SE_IMPRINT,
  SE_PRIVACY_NOTICE: LINKS.LINK_SE_PRIVACY_NOTICE,
  SE_TERMS_OF_USE: LINKS.LINK_SE_TERMS_OF_USE,
  SIGNING_KEY: LINKS.LINK_PUBLIC_SIGNING_KEY,
  USER_SATISFACTION_DEV: LINKS.API_USER_SATISFACTION_DEV,
  USER_SATISFACTION_PROD: LINKS.API_USER_SATISFACTION_PROD,
  ICS_PORTAL: LINKS.LINK_ICS_PORTAL
};

export const PacTSRedirectGateway = () => {
  const [to] = useSearchParameter('to') as [RedirectKeys, unknown];

  const redirect = Object.keys(Redirects).find((k) => Redirects[k as RedirectKeys].endsWith(`to=${to}`)) as RedirectKeys;
  if (!(redirect in associations)) return <NotFoundForwarder />;

  return <Redirect to={associations[redirect]} />;
};
