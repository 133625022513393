import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';
import { Redirects } from "@pacts/utils-redirects";

export const ChecksumHowTo = () => {
  const permissions = usePermissions();

  if (!permissions.webui$showHelp) return null;

  return (
    <Tooltip title="How To: Checksums and Integrity">
      <Typography.Link href={Redirects.WIKI_PACTS_INTEGRITY} target="_blank">
        {<QuestionCircleOutlined />}
      </Typography.Link>
    </Tooltip>
  );
};
