import styled from 'styled-components';
import { Typography, Row, Result, Col, Alert, Button as AntButton, Select, Space } from 'antd';
import {
  CloseCircleFilled as AntCloseCircleFilled,
  CheckCircleFilled as AntCheckCircleFilled,
  MinusCircleFilled as AntMinusCircleFilled
} from '@ant-design/icons';

interface TextProps {
  color?: string | null;
  textTransform?: 'capitalize' | 'none' | 'uppercase' | 'lowercase' | 'full-width' | null;
}

export const Text = styled(Typography.Text)<TextProps>`
  ${(props) =>
    props.color != null
      ? `
        color: ${props.color} !important;
    `
      : ''}

  ${(props) =>
    props.textTransform != null
      ? `
        text-transform: ${props.textTransform} !important;
    `
      : ''}
`;

export const ErrorCircleFilled = styled(AntCloseCircleFilled)<{ size?: number }>`
  color: ${({ theme }) => theme.colorError};
  font-size: ${({ theme, size }) => size ?? theme.fontSizeLG}px;
`;

export const SuccessCircleFilled = styled(AntCheckCircleFilled)<{ size?: number }>`
  color: ${({ theme }) => theme.colorSuccess};
  font-size: ${({ theme, size }) => size ?? theme.fontSizeLG}px;
`;

export const WarningCircleFilled = styled(AntMinusCircleFilled)<{ size?: number }>`
  color: ${({ theme }) => theme.colorWarningSecondary};
  font-size: ${({ theme, size }) => size ?? theme.fontSizeLG}px;
`;

export const StyledRow = styled(Row)<{ width?: string; height?: string; marginTop?: string }>`
  ${({ width }) => (width ? `width: ${width};` : '')}
  ${({ height }) => (height ? `height: ${height};` : '')}
  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop};` : '')}
`;

export const StyledCol = styled(Col)<{ textAlign?: string; marginTop?: string }>`
  ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : '')}
  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop};` : '')}
`;

export const StyledResult = styled(Result)<{
  padding?: string;
  iconStyle?: { iconFontSize?: string };
  contentStyle?: { backgroundColor?: string; padding?: string };
}>`
  ${({ padding }) => (padding ? `padding: ${padding};` : '')}
  ${(props) => `
  div.ant-result-icon > span {
    font-size: ${props.iconStyle?.iconFontSize ? props.iconStyle?.iconFontSize : `${props.theme.fontSizeHeading3}px`} !important
  }
  div.ant-result-content {
    background-color: ${props.contentStyle?.backgroundColor ? props.contentStyle?.backgroundColor : `undefined`};
    padding: ${props.contentStyle?.padding ? props.contentStyle?.padding : `undefined`};
  }
  `}
`;

export const StyledAlert = styled(Alert)<{ padding?: string }>`
  ${({ padding }) => (padding ? `padding: ${padding};` : '')}
`;

export const Button = styled(AntButton)<{ color?: 'primary' | 'secondary' }>`
  ${({ theme, color }) => color === 'secondary' ? `background: ${theme.colorSecondary};` : '' }
  ${({ theme, color }) => color === 'primary' ? `background: ${theme.colorPrimary};` : '' }
`;

export const FullWidthSelect = styled(Select)`
  width: 100%;
`

export const FullWidthSpace = styled(Space)`
  width: 100%;
`;
