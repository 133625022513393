import { Alert, Space, Steps, Tooltip, Typography } from 'antd';
import { OauthDeviceCodeResponse } from '../../api/auth/domain/auth';
import { InfoCircleFilled } from '@ant-design/icons';
import { Copyable } from './Copyable';
import ExpirationTimer from './ExpirationTimer';
import styled from 'styled-components';
import { usePermissions } from '../session/hooks/usePermissions';
import { Redirects } from '@pacts/utils-redirects';

const StyledTitle = styled(Typography.Title)`
  text-align: center;
  font-size: 20px !important;
  border-bottom: 1px solid ${({ theme }) => theme.colorBorderSecondary} !important;
  padding-bottom: 13px !important;
`;

const SpaceDiv = styled.div`
  margin-bottom: 37px;
`;

const StyledSteps = styled(Steps)`
  margin-top: 34px !important;
`;

export const ExternalTokenCreatePage = (props: { verificationUri?: string; isLoading?: boolean; tokenResponse?: OauthDeviceCodeResponse }) => {
  const permissions = usePermissions();

  return (
    <>
      <Typography.Text>
        Generate tokens to integrate your application to PacTS by accessing PacTS API. <br />
      </Typography.Text>
      {permissions.webui$showHelp && (
        <Typography.Text>
          Please read the{' '}
          <Typography.Link target="_blank" href={Redirects.WIKI_PACTS_TOKENS}>
            Wiki documentation
          </Typography.Link>{' '}
          before starting.
        </Typography.Text>
      )}
      <StyledTitle>Steps</StyledTitle>
      <Alert message="Once the steps are completed, this dialog will show the generated refresh token." type="info" showIcon />
      <StyledSteps
        direction="vertical"
        items={[
          {
            status: 'process',
            title: (
              <Typography.Text>
                Open the following link in incognito mode or in another browser.{' '}
                <Tooltip
                  title={
                    <Space direction="vertical" size="large">
                      Incognito or opening in another browser is used to make sure that you are not automatically logged in using your Siemens Energy account.
                    </Space>
                  }
                >
                  <InfoCircleFilled style={{ cursor: 'pointer' }} />
                </Tooltip>
              </Typography.Text>
            ),
            description: (
              <Copyable
                isLoading={props.verificationUri == null}
                contentType="link"
                // The style to copyable is actually a prop not inline style
                style={{ width: '341px', marginLeft: 0, marginBottom: '37px' }}
                content={props.verificationUri}
              />
            )
          },
          {
            status: 'process',
            title: <Typography.Text>Enter the following code in the login window.</Typography.Text>,
            description: (
              <>
                {/* The style to copyable is actually a prop not inline style */}
                <Copyable style={{ marginLeft: 0, width: '341px' }} isLoading={props.isLoading} content={props.tokenResponse?.user_code} />
                {props.tokenResponse?.expires_in != null && <ExpirationTimer expiresIn={props.tokenResponse?.expires_in} />}
                <SpaceDiv />
              </>
            )
          },
          {
            status: 'process',
            title: (
              <Typography.Text>
                <Typography.Text strong>Login</Typography.Text> using a <Typography.Text strong>Siemens Energy account</Typography.Text>. Using functional
                accounts is highly recommended!
              </Typography.Text>
            )
          }
        ]}
      />
    </>
  );
};
