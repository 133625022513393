import { Typography } from 'antd';
import styled from 'styled-components';

import { THEMES, useTheme } from '../../theme/ThemeProvider';


const Text = styled(Typography.Text)<{ isSuccess?: boolean, isLightTheme: boolean }>`
	color: ${({ theme, isSuccess, isLightTheme }) => {
		if (isSuccess) {
			return isLightTheme ? theme.primitivesLight.green[6] : theme.colorSuccess;
		}

		return isLightTheme ? theme.primitivesLight.orange[5] : theme.primitivesDark.orange[7];
	}};
`;

type Props = {
	children: React.ReactNode;
  /** Make the text green / success color. */
	isSuccess?: boolean;
};

export const InstalledText = (props: Props) => {
	const { children, isSuccess } = props;

	const [theme] = useTheme();
  const isLightTheme = theme === THEMES.LIGHT;

	return <Text isLightTheme={isLightTheme} isSuccess={isSuccess}>{children}</Text>;
}