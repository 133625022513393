import { Card, Row, Col, Space, Divider, Typography, Tag } from 'antd';
import { Project } from '../../../api';
import { useInAppNavigate } from '../../navigation/hooks/useInAppNavigate';
import { usePermissions } from '../../session/hooks/usePermissions';
import { DashboardButton } from './DashboardButton';
import { ProjectActions } from './ProjectActions';
import { ProjectDetails } from './ProjectDetails';
import { ProjectMemberList } from './ProjectMemberList';
import { ReportTargets } from './ReportTargets';
import { CalculatorOutlined } from '@ant-design/icons';
import { aisForceConfig } from '@p-apps/ais-force/config';
import { CenterMenuElement } from '../../../contexts/shared/components/CenterMenuElement';
import { AppIcon } from '../../../contexts/shared/components/AppIcon';
import { ToolIcon } from '../../../contexts/shared/components/ToolIcon';
import { DeploymentIcon } from '../../../contexts/shared/components/DeploymentIcon';

export const SelectedProjectCard = (props: { selectedProject: Project; minHeight: string }) => {
  const permissions = usePermissions({ projectId: props.selectedProject.idProject.toString() });
  const navigate = useInAppNavigate();
  const elementSpan = { sm: 24, xl: 12 };

  return (
    <Card style={{ minHeight: props.minHeight }}>
      <Row>
        <Col span={24}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Row gutter={[8, 8]}>
              <Col flex="auto">
                <Typography.Title level={4} style={{ marginBottom: 0 }}>
                  {props.selectedProject.name}
                </Typography.Title>
              </Col>
              <Col flex="32px">
                <ProjectActions project={props.selectedProject} />
              </Col>
            </Row>
            <Space style={{ float: 'left', marginTop: 4 }} align="baseline" split={<Divider type="vertical" />} wrap>
              {props.selectedProject.isArchived ? <Tag style={{ margin: 0 }}>Archived</Tag> : null}
              <Typography.Text type="secondary">{`Status: ${props.selectedProject.status}`}</Typography.Text>
              <Typography.Text type="secondary">{`Phase: ${props.selectedProject.phase.name}`}</Typography.Text>
              <Typography.Text type="secondary">{`Solution Type: ${props.selectedProject.projectSolutionType.name}`}</Typography.Text>
              {props.selectedProject.referenceProjectRelease && (
                // eslint-disable-next-line max-len
                <Typography.Text type="secondary">{`RPL: ${props.selectedProject.referenceProjectRelease.projectName} / ${props.selectedProject.referenceProjectRelease.releaseVersion}`}</Typography.Text>
              )}
            </Space>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          <ProjectDetails project={props.selectedProject} />
        </Col>
      </Row>
      <Row gutter={[32, 32]}>
        <Col span={24} style={{ marginTop: 32 }}>
          <Row gutter={[16, 16]}>
            <Col {...elementSpan}>
              <CenterMenuElement
                icon={<AppIcon />}
                title="Apps"
                subtitle={`${props.selectedProject.name} Software Apps`}
                onClick={() => navigate(`/projects/${props.selectedProject.idProject}/apps`)}
              />
            </Col>
            <Col {...elementSpan}>
              <CenterMenuElement
                icon={<ToolIcon />}
                title="Tools"
                subtitle={`${props.selectedProject.name} Engineering Tools`}
                onClick={() => navigate(`/projects/${props.selectedProject.idProject}/tools`)}
              />
            </Col>
            {permissions.deploymentSvc$getDeploymentPlans && (
              <Col {...elementSpan}>
                <CenterMenuElement
                  icon={<DeploymentIcon />}
                  title="Deployments"
                  subtitle={`${props.selectedProject.name} Deployments`}
                  onClick={() => navigate(`/projects/${props.selectedProject.idProject}/deployments/environments`)}
                />
              </Col>
            )}
            {permissions.webui$showDashboards && (
              <Col {...elementSpan}>
                <DashboardButton target={props.selectedProject} />
              </Col>
            )}
            <Col {...elementSpan}>
              <ReportTargets project={props.selectedProject} />
            </Col>
            {permissions.pAisForceSvc$calculateFlexibleConductor && permissions.pAisForceSvc$getFlexibleConductorCalculations && (
              <Col {...elementSpan}>
                <CenterMenuElement
                  icon={<CalculatorOutlined />}
                  title={aisForceConfig.name}
                  subtitle={`${props.selectedProject.name} ${aisForceConfig.name}`}
                  onClick={() => navigate(`/projects/${props.selectedProject.idProject}/${aisForceConfig.route}`)}
                />
              </Col>
            )}
          </Row>
        </Col>
        {permissions.engineeringSvc$getProjectMembers && (
          <Col span={24}>
            <ProjectMemberList project={props.selectedProject} />
          </Col>
        )}
      </Row>
    </Card>
  );
};
