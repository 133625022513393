import { Alert, Typography } from 'antd';
import { AuthTokenResponse } from '../../api/auth/domain/auth';
import { useMemo } from 'react';
import { formatDateTime, getEstimatedPeriod } from '../shared/components/formatDate';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';
import { Copyable } from './Copyable';
import * as GlobalStyled from '../shared/base/Components.styled';
import styled from 'styled-components';
import { usePermissions } from '../session/hooks/usePermissions';
import { Redirects } from '@pacts/utils-redirects';

const StyledTitle = styled(Typography.Title)`
  text-align: center;
  font-size: 20px;
  margin-top: 31px;
`;

const StyledParagraph = styled(Typography.Paragraph)<{ mt: number }>`
  margin-top: ${(props) => props.mt}px;
`;

const SuccessText = styled(Typography.Text)`
  color: ${({ theme }) => theme.colorSuccess} !important;
`;

export const ExternalTokenResultPage = (props: { tokenResponse?: AuthTokenResponse }) => {
  const permissions = usePermissions();

  const accessTokenMeta = useMemo(() => {
    if (!props.tokenResponse?.accessToken) return {};
    try {
      const token = jwtDecode(props.tokenResponse.accessToken) as any;
      return token;
    } catch (error) {
      return {};
    }
  }, [props.tokenResponse?.accessToken]);

  const refreshTokenMeta = useMemo(() => {
    if (!props.tokenResponse?.refreshToken) return {};
    try {
      const token = jwtDecode(props.tokenResponse.refreshToken) as any;
      token.exp_parsed = formatDateTime(dayjs.unix(token.exp).toDate());
      return token;
    } catch (error) {
      return {};
    }
  }, [props.tokenResponse?.refreshToken]);
  const estimatedAvailabilityPeriod = useMemo(
    () => (refreshTokenMeta.exp_parsed != null ? getEstimatedPeriod(String(refreshTokenMeta.exp_parsed).split(',')[0]) : null),
    [refreshTokenMeta.exp_parsed]
  );

  return (
    <>
      <StyledTitle>
        <GlobalStyled.SuccessCircleFilled size={25} /> Token generated successfully!
      </StyledTitle>
      <StyledParagraph mt={37}>The following refresh token can now be used to create access tokens.</StyledParagraph>
      <Alert message="Existing refresh tokens cannot be retrieved again. Please save your Refresh Token in a secure way." type="warning" showIcon />
      <StyledParagraph mt={51}>
        <Typography.Text strong>Refresh token:</Typography.Text>
        <Copyable content={props.tokenResponse?.refreshToken} />
      </StyledParagraph>
      <Typography.Paragraph>Login account: {accessTokenMeta.username}</Typography.Paragraph>
      <Typography.Paragraph>
        Validity period: {estimatedAvailabilityPeriod != null && <SuccessText strong>{estimatedAvailabilityPeriod}</SuccessText>}
      </Typography.Paragraph>
      <Typography.Paragraph>Expiration date: {refreshTokenMeta.exp_parsed}</Typography.Paragraph>
      {permissions.webui$showHelp && (
        <StyledParagraph mt={51}>
          <Typography.Text strong>Create new access tokens:</Typography.Text> check our{' '}
          <Typography.Link target="_blank" href={Redirects.WIKI_PACTS_TOKENS}>
            Wiki page.
          </Typography.Link>
        </StyledParagraph>
      )}
    </>
  );
};
