import { useParams } from 'react-router-dom';
import { useProject } from '../../projects/hooks/useProject';
import { useLatestReportRevision } from '../hooks/useLatestReport';
import { useFlatifiedReports } from '../hooks/useFlatifiedReports';
import { ReportTable } from './ReportTable';
import { Space, Skeleton, Row, Col } from 'antd';
import { useSources } from '../hooks/useSources';
import { ColumnSelect } from './ColumnSelect';
import { useState } from 'react';
import { BackToProjectButton } from '../../navigation/components/BackToProjectButton';
import { FullWidthLayout } from '../../layout/components/FullWidthLayout';
import { ReportMetadata } from './ReportMetadata';
import { RevisionSelector } from './RevisionSelector';
import { ReportMetadataHeadline } from './ReportMetadataHeadline';
import { ReportErrorResult } from './ReportErrorResult';
import { useReportRevision } from '../hooks/useReportRevision';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';
import { AnimatedLoadingIcon } from '../../../contexts/shared/components/icons/AnimatedLoadingIcon';

const preferredColumnsLocalStorageKey = 'report-preferred-columns';

export const Report = () => {
  const params = useParams<{ projectid: string; sourceid: string; revision: string }>();

  const projectId = parseInt(params.projectid || '-1', 10);
  const project = useProject(projectId.toString());

  const isLatestRevision = params.revision && params.revision === 'latest';
  const isNumericRevision = params.revision && params.revision !== 'latest';
  const latestReport = useLatestReportRevision(projectId, params.sourceid || '', !isLatestRevision);
  const numericReport = useReportRevision(projectId, params.sourceid || '', parseInt(params.revision ?? ''), !isNumericRevision);

  const report = isLatestRevision ? latestReport : numericReport;

  const sources = useSources(projectId);
  const flatifiedReports = useFlatifiedReports(report.data);

  const source = sources.data?.find((s) => s.id === params.sourceid);
  const sourceId = source?.id || '';

  const [selectedColumns, setSelectedColumns] = useState<string[] | null>(null);

  // If a report does not contain any documents or columns it is considered empty
  const flatifiedHasDigest = !!flatifiedReports?.digest;
  const flatifiedHasReportsAndColumns = (flatifiedReports?.documents.length ?? 1) > 0 && (flatifiedReports?.columns.length ?? 1) > 0;
  const emptyReport = flatifiedHasDigest && !flatifiedHasReportsAndColumns;

  const isError = report.isError || project.isError || emptyReport;

  const contextIsLoading = (!project.isSuccess || !sources.isSuccess) && !isError;
  const reportIsLoading = (contextIsLoading || !report.isSuccess) && !isError;

  const hasColumns = !!selectedColumns && selectedColumns.length > 0;
  const reportsHaveColumns = (flatifiedReports?.columns?.length || 0) > 0;

  const permissions = usePermissions({ projectId: String(projectId) });
  const isReportsAdmin =
    permissions.reportSvc$createReportRevision ||
    permissions.reportSvc$putReportFolderColumns ||
    permissions.reportSvc$putReportSystemColumns;

  const canRenderReport = !reportIsLoading && reportsHaveColumns && flatifiedReports && hasColumns;

  // Make sure to render the table once the initially selected columns have been set by the ColumnSelect callback.
  // This is required for the table filter to work with initial data.
  const table = canRenderReport ? (
    <ReportTable
      key={flatifiedReports.digest}
      data={flatifiedReports}
      columns={selectedColumns!}
      source={source!}
      project={project.data!}
      revision={report.data!.meta}
    />
  ) : (
    <Skeleton active />
  );

  const pageContent = isError ? <ReportErrorResult type={emptyReport ? 'empty' : 'error'} /> : table;

  const title = contextIsLoading ? <AnimatedLoadingIcon /> : `${project.data?.name ?? ''} ${source?.displayName ?? ''}`;

  const selector = (
    <Row align="middle" gutter={[0, 8]}>
      <Col>
        <RevisionSelector project={project.data} selectedRevision={params.revision} source={source} />
      </Col>
      <Col>
        <ReportMetadataHeadline report={report.data} />
      </Col>
    </Row>
  );

  return (
    <FullWidthLayout
      padding
      fixed
      title={title}
      cardInnerHeader={selector}
      cardInnerHeaderExtra={
        <Space>
          {isReportsAdmin && <ReportMetadata key="metadata" revision={report.data?.meta} source={source} loading={reportIsLoading} disabled={isError} />}
          <ColumnSelect
            disabled={isError}
            loading={reportIsLoading}
            key="columns"
            sourceId={sourceId}
            projectId={projectId}
            data={flatifiedReports}
            onSelect={setSelectedColumns}
            localStorageKey={preferredColumnsLocalStorageKey}
          />
        </Space>
      }
      extra={[
        <Space key="extra">
          <BackToProjectButton key="btp" projectId={params.projectid || ''} />
        </Space>
      ]}
    >
      {pageContent}
    </FullWidthLayout>
  );
};
