import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Input, Progress, Row, Space, Tag, Tooltip, Typography, Upload } from 'antd';
import { CloseCircleFilled, FileOutlined, LinkOutlined } from '@ant-design/icons';
import axios, { AxiosRequestConfig } from 'axios';
import { RcFile } from 'antd/es/upload';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { useCurrentUser, usePermissions } from '../../session';

import type { UploadProps, UploadFile } from 'antd';
import { usePacTSFormationAvailable } from '../../session/hooks/usePacTSFormationAvailable';
import styled from 'styled-components';

// 4.8GB since s3 upload is limited to 5gb
const maxFileSize = 5033164800;

const WideInput = styled(Input)`
  min-width: 350px;
`;

const ClippedText = styled(Typography.Text)`
  max-width: 40ch !important;
`;

const WideSpace = styled(Space)`
  width: 100%;
`;

export type FileSelection = {
  link: string;
  file?: {
    name: string;
    mimeType: string;
    extension: string;
  };
};

const blockedExtensions = ['log', 'ini'];

export const UploadAnt = (props: {
  onChange?: (value: FileSelection) => any;
  initialValue?: string;
  showError?: boolean;
  accept?: string;
  uploadDisabled?: boolean;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { backend } = useEngineeringBackend();
  const user = useCurrentUser();
  const permissions = usePermissions();

  const [manualLink, setManualLink] = useState(props.initialValue || '');
  const [readableError, setReadableError] = useState('');
  const [temporaryFile, setTemporaryFile] = useState<FileSelection | undefined>(undefined);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [sanitizedUploadFileName, setSanitizedUploadFileName] = useState<string | undefined>(undefined);

  const hasFiles = fileList.length > 0;
  const currentFile = hasFiles ? fileList[0] : undefined;

  const pacTSFormationAvailable = usePacTSFormationAvailable();
  const hasPermissions = permissions.engineeringSvc$getPresignedUploadUrl;
  const hideUploadButton = props.uploadDisabled === true || !hasPermissions || !pacTSFormationAvailable;

  const placeHolder = hasFiles ? '' : hideUploadButton ? 'Enter link' : 'Enter custom link or select file to upload';

  const errorStatus = readableError !== '' || props.showError;

  const controller = useRef(new AbortController());

  const resetUpload = useCallback(() => {
    if (!hasFiles) return;
    setLoading(false);
    setFileList([]);
    setSanitizedUploadFileName(undefined);
    controller.current.abort();
    setReadableError('');
    setTemporaryFile(undefined);
  }, [hasFiles, setLoading, setFileList, setSanitizedUploadFileName, setReadableError, setTemporaryFile]);

  useEffect(() => {
    // Make sure to cancel any upload when the component unloads
    return () => {
      try {
        controller.current.abort();
      } catch {
        /* */
      }
    };
  }, []);

  const targetLink = temporaryFile || { link: manualLink };
  const lastEmittedUploadName = useRef(targetLink);

  useEffect(() => {
    if (lastEmittedUploadName.current.link === targetLink.link) return;
    lastEmittedUploadName.current = targetLink;
    if (props.onChange) props.onChange(targetLink);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetLink]);

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: true,
    showUploadList: false,
    disabled: loading || props.uploadDisabled,
    fileList: fileList,
    accept: props.accept,
    onChange: (info) => setFileList(info.fileList),
    maxCount: 1,
    customRequest: async ({ file, onError, onSuccess, onProgress }) => {
      const setError = (err: Error) => {
        onError!(err as any);
        setReadableError(err.message);
      };

      setManualLink('');
      setReadableError('');
      setTemporaryFile(undefined);
      const rcFile = file as RcFile;
      const fileType = rcFile.type || 'application/octet-stream';
      const fileName = rcFile.name;
      const fileExtension = rcFile.name.split('.').pop();
      const allowedExtensions = props.accept?.replaceAll('.', '').split(',').filter(Boolean);

      if (!fileExtension) {
        return setError(new Error('No file extension provided.'));
      }
      if (allowedExtensions && !allowedExtensions?.includes(fileExtension)) {
        // This is copied from the form item as the validation there happens at a different step of the process
        // make sure to keep the error messages in sync
        return setError(new Error(`The only file extensions allowed are ${allowedExtensions.join(', ')}.`));
      }

      if (blockedExtensions.includes(fileExtension)) {
        // Some file extensions are blocked for security reasons by the infrastructure (WAF)
        // they shall never be allowed to upload
        return setError(new Error(`File extension '${fileExtension}' is not allowed.`));
      }

      if (rcFile.size > maxFileSize) {
        return setError(new Error('The file is larger than the allowed maximum of 4.8GB.'));
      }

      try {
        setLoading(true);
        const res = await backend.getTemporaryBlobUploadToken(fileType, fileName);
        setSanitizedUploadFileName(res.fileName);

        // make sure to create a new controller before each upload
        controller.current = new AbortController();
        var options: AxiosRequestConfig = {
          onUploadProgress: (event: ProgressEvent) => {
            const { loaded, total } = event;
            onProgress!({
              percent: Math.round((loaded / total) * 100)
            });
          },
          signal: controller.current.signal,
          headers: {
            'Content-Type': fileType,
            'x-amz-meta-pacts-userid': user.id,
            'x-amz-meta-pacts-username': user.name,
            'x-amz-meta-pacts-usergid': user.gid ?? 'unknown',
            'x-amz-meta-filename': res.fileName
          }
        };
        const result = await axios.put(res.uploadUrl, file, options);
        onSuccess!(result);
        setTemporaryFile({ link: `tmp://${res.temporaryFileName}`, file: { name: fileName, mimeType: fileType, extension: fileExtension || '' } });
      } catch (error) {
        const isExpectedError = (error as any).message === 'canceled';
        if (!isExpectedError) {
          console.error(error);
          onError!(error as any);
          setReadableError('An unexpected error happened, please try again.');
        }
      } finally {
        setLoading(false);
      }
    },
    onDrop(e) {
      console.warn('Dropped files', e.dataTransfer.files);
    }
  };

  const uploadInput = useMemo(() => {
    const ClearIcon = (clearProps: { tooltip: string; error?: boolean }) => (
      <Tooltip title={clearProps.tooltip}>
        <Typography.Link type={clearProps.error ? 'danger' : 'secondary'}>
          <CloseCircleFilled
            onClick={() => {
              resetUpload();
            }}
          />
        </Typography.Link>
      </Tooltip>
    );

    const uploadProgress = currentFile?.status ? (
      <Space>
        {currentFile.status === 'uploading' && <ClearIcon tooltip="Cancel Upload" error={errorStatus} />}
        {currentFile.status === 'error' && <ClearIcon tooltip="Remove File" error />}
        {currentFile.status === 'done' && <ClearIcon tooltip="Remove File" error={errorStatus} />}
      </Space>
    ) : null;

    const clearIcon = {
      clearIcon: (
        <Typography.Link type={errorStatus ? 'danger' : 'secondary'}>
          <CloseCircleFilled />
        </Typography.Link>
      )
    };

    const prefix = hasFiles ? (
      <Space>
        <FileOutlined />
        {sanitizedUploadFileName ? (
          <Tag>
            <ClippedText
              ellipsis={{
                tooltip: sanitizedUploadFileName
              }}
            >
              {sanitizedUploadFileName}
            </ClippedText>
          </Tag>
        ) : null}
      </Space>
    ) : (
      <LinkOutlined />
    );

    return (
      <WideInput
        status={errorStatus ? 'error' : undefined}
        prefix={prefix}
        placeholder={placeHolder}
        allowClear={!hasFiles ? clearIcon : false}
        value={hasFiles ? '' : manualLink}
        suffix={uploadProgress}
        onChange={(e) => {
          if (hasFiles) return;
          setManualLink(e.target.value);
        }}
      />
    );
  }, [errorStatus, hasFiles, manualLink, placeHolder, currentFile?.status, resetUpload, sanitizedUploadFileName]);

  const hasSubRow = readableError || (currentFile && currentFile.status === 'uploading');

  return (
    <WideSpace direction="vertical">
      <Row wrap={false} gutter={[8, 0]} align="middle">
        <Col flex="auto">{uploadInput}</Col>
        {!hideUploadButton ? (
          <Col flex="none">
            <Upload {...uploadProps}>
              <Button loading={loading} disabled={props.uploadDisabled}>
                Select File
              </Button>
            </Upload>
          </Col>
        ) : null}
      </Row>
      {hasSubRow ? (
        <Row>
          {readableError ? <Typography.Text type="danger">{readableError}</Typography.Text> : null}
          {currentFile && currentFile.status === 'uploading' ? <Progress percent={currentFile.percent} format={(number) => `${number}%`} /> : null}
        </Row>
      ) : null}
    </WideSpace>
  );
};
