import { Skeleton, TableColumnsType } from 'antd';

import { UpdateCommonSoftwareAppVersion } from './UpdateCommonSoftwareAppVersion';
import { SoftwareAppsOpenInToolManagerButton } from './SoftwareAppsOpenInToolManagerButton';
import { SemanticVersion } from '../../../domain/versioning/semanticVersion';
import Table from '../../../contexts/shared/components/Table/Table';
import { ComponentVersionListEntry } from '../../../contexts/shared/components/ComponentVersionListEntry';
import { DownloadContextMenuButton } from '../../shared/components/Download/components/DownloadContextMenuButton';
import { SoftwareAppVersionListTitle } from './SoftwareAppVersionListTitle';

import { usePermissions } from '../../session/hooks/usePermissions';
import { scopeToCommon, useCommonSoftwareAppVersions, useDeleteCommonSoftwareAppVersion } from '../hooks';

import type { Project, SoftwareApp, SoftwareAppVersion } from '../../../api/engineering/domain/types';

import { useCallback, useMemo } from 'react';
import { ExpandableMenu } from '../../../contexts/shared/components/ExpandableMenu';
import { ConfirmationButton } from '../../../contexts/shared/components/ConfirmationButton';
import { useTableSearch } from '../../../contexts/shared/components/TableSearch';
import { formatDateTime } from '../../../contexts/shared/components/formatDate';

export const CommonSoftwareAppVersionsList = (props: { softwareApp: SoftwareApp; project?: Project; extraHeaderOffset: number }) => {
  const permissions = usePermissions({
    projectId: props.project?.idProject.toString(),
    softwareAppId: props.softwareApp.idSoftwareApp.toString()
  });

  const versionSearch = useTableSearch({ searchValueProvider: 'version', searchParamId: 'version' });

  const releaseNotesSearch = useTableSearch({
    searchValueProvider: (v: SoftwareAppVersion) => {
      return [v.version, v.releaseNotes, formatDateTime(new Date(v.createdAt || 0)), v.createdBy, formatDateTime(new Date(v.updatedAt || 0)), v.updatedBy]
        .filter(Boolean)
        .join(' ');
    },
    searchParamId: 'releaseNotes'
  });

  const versions = useCommonSoftwareAppVersions(props.softwareApp.idSoftwareApp?.toString() || '');
  const deleteSoftwareAppVersion = useDeleteCommonSoftwareAppVersion();

  const latestVersion = props.softwareApp?.latestVersion?.idSoftwareAppVersion;
  const versionSorter = useCallback(
    (a: SoftwareAppVersion, b: SoftwareAppVersion) => {
      if (a.idSoftwareAppVersion === latestVersion) return -1;
      if (b.idSoftwareAppVersion === latestVersion) return 1;
      return SemanticVersion.sorter(a.version, b.version);
    },
    [latestVersion]
  );

  const data = useMemo(() => {
    return (versions.data ?? []).sort(versionSorter);
  }, [versions.data, versionSorter]);

  const columns: TableColumnsType<SoftwareAppVersion> = [
    {
      title: 'Version',
      key: 'version',
      width: 160,
      ...versionSearch,
      render: (record: SoftwareAppVersion) => {
        return <SoftwareAppVersionListTitle app={props.softwareApp} version={record} />;
      }
    },
    {
      title: 'Release Notes',
      key: 'releaseNotes',
      showSorterTooltip: { title: 'Sort by creation date' },
      sorter: {
        compare: (a: SoftwareAppVersion, b: SoftwareAppVersion): number => {
          return new Date(a.createdAt ?? 0).getTime() - new Date(b.createdAt ?? 0).getTime();
        }
      },
      ...releaseNotesSearch,
      render: (record: SoftwareAppVersion) => <ComponentVersionListEntry record={record} showAudit={permissions.webui$showComponentDetails} />
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record: SoftwareAppVersion) => {
        return (
          <>
            <ExpandableMenu textType id={`common-app-version-actions-${props.softwareApp.idSoftwareApp}-${record.idSoftwareAppVersion}`}>
              <DownloadContextMenuButton artifact={props.softwareApp} version={record} />
              <SoftwareAppsOpenInToolManagerButton selection={{ app: scopeToCommon(props.softwareApp), version: record }} project={props.project} />
              <UpdateCommonSoftwareAppVersion parentApp={props.softwareApp} softwareAppVersion={record} />
              {data.length > 1 && permissions.engineeringSvc$deleteCommonSoftwareAppVersion && (
                <ConfirmationButton
                  caption="Delete"
                  danger
                  disabled={record.idSoftwareAppVersion === props.softwareApp.latestVersion?.idSoftwareAppVersion}
                  onOk={() => {
                    deleteSoftwareAppVersion.mutate([props.softwareApp.idSoftwareApp?.toString() || '', record.idSoftwareAppVersion?.toString() || '']);
                  }}
                />
              )}
            </ExpandableMenu>
          </>
        );
      },
      width: 100
    }
  ];

  return (
    <Skeleton loading={versions.isLoading}>
      <Table
        tableLayout="fixed"
        columns={columns}
        rowKey={(record: SoftwareAppVersion) => record.idSoftwareAppVersion?.toString() || ''}
        dataSource={data}
        sticky={{
          offsetHeader: 127 + props.extraHeaderOffset + 14
        }}
      />
    </Skeleton>
  );
};
