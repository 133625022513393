import { useContext, useMemo } from 'react';
import { Menu } from 'antd';
import { UserSwitchOutlined, HomeOutlined, DiffOutlined, NotificationOutlined, ApartmentOutlined } from '@ant-design/icons';
import { Location, NavLink, useLocation } from 'react-router-dom';
import { PacTSPermissions, PermissionsCalculator } from '@pacts/permissions-lib';
import { PacTSContext } from '../../../state/store';
import { useProjects } from '../../projects/hooks';
import { usePermissions } from '../../session/hooks/usePermissions';
import { Comparator } from '../../../domain/extensions/comparison';
import { ProjectTypeIcon } from '../../projects/components/ProjectIcon';
import { useInAppNavigate } from '../hooks/useInAppNavigate';
import { useSession } from '../../../contexts/session/hooks/useSession';
import styled from 'styled-components';
import { uniqBy } from 'lodash';
import { AppIcon } from '../../../contexts/shared/components/AppIcon';
import { ToolIcon } from '../../../contexts/shared/components/ToolIcon';
import { DeploymentIcon } from '../../../contexts/shared/components/DeploymentIcon';

const StyledMenu = styled(Menu)`
  .ant-menu-item,
  .ant-menu-submenu-horizontal .ant-menu-submenu-title {
    color: ${({ theme }) => theme.topMenuTextColor};
  }

  .ant-menu-item * {
    color: ${({ theme }) => theme.topMenuTextColor};
  }
`;

const { SubMenu: AntSubMenu } = StyledMenu;

const SubMenu = styled(AntSubMenu)`
  &.ant-menu-submenu-vertical.ant-menu-submenu-active .ant-menu-submenu-title {
    color: white !important;
    background-color: ${({ theme }) => theme.Menu?.horizontalItemSelectedBg} !important;
  }

  &.ant-menu-submenu-vertical.ant-menu-submenu-selected .ant-menu-submenu-title {
    background-color: ${({ theme }) => theme.Menu?.horizontalItemSelectedBg} !important;
  }
`;

function getSelectedKeys(location: Location) {
  // In order for the menu to show correct active state
  // the selectedKeys have to be modified to match the route hierarchy
  const subdirectories = location.pathname.split('/').filter((path) => path.length > 0);
  const isSubdirectorySelected = subdirectories.length > 1;

  if (location.search.includes('type=')) {
    return [new URLSearchParams(location.search).get('type') ?? '1'];
  } else if (isSubdirectorySelected) {
    const rootDirectory = `/${subdirectories.find((path) => path.length > 0)}`;
    return [rootDirectory];
  }

  return [location.pathname];
}

export const TopMenu = () => {
  const location = useLocation();
  const permissions = usePermissions();
  const projects = useProjects();
  const navigate = useInAppNavigate();
  const [state] = useContext(PacTSContext);
  const session = useSession(state);
  const projectPermissions = useMemo(() => {
    const projPerms = new Map<string, PacTSPermissions>();
    if (projects.data && state.token) {
      projects.data?.forEach((p) => {
        projPerms.set(
          p.idProject!.toString(),
          PermissionsCalculator.calculate(session.userInfo?.permissions ?? {}, { projectId: p.idProject?.toString() }).calculatedPermission
        );
      });
    }
    return projPerms;
  }, [projects.data, state.token, session.userInfo?.permissions]);

  const showHome = permissions.engineeringSvc$getProjectTypes || permissions.engineeringSvc$getProject$specific().length > 0;
  const canAdministrateProjects = Array.from(projectPermissions.values()).some((p) => p.engineeringSvc$updateProjectMember);
  const canAdministrateUsers = permissions.userSvc$getUsers && permissions.userSvc$getRolesOfUser && permissions.userSvc$updateRolesOfUser;
  const canAdministratePf = permissions.pactsFormationSvc$unrestrictedAdministration;
  const hasAdminFeatures = canAdministrateProjects || canAdministrateUsers || canAdministratePf;

  const canUseEngineeringPlatform = permissions.engineeringSvc$getTools || permissions.engineeringSvc$getTool$specific().length > 0;
  const canUseSoftwarePlatform = permissions.engineeringSvc$getCommonSoftwareApps || permissions.engineeringSvc$getCommonSoftwareApp$specific().length > 0;
  const canUseProjects = permissions.engineeringSvc$getProjects || permissions.engineeringSvc$getProject$specific().length > 0;

  const canUseProjectBundles = permissions.engineeringSvc$getProjectBundles || permissions.engineeringSvc$getProjectBundles$specific().length > 0;

  const selectedKeys = getSelectedKeys(location);

  const projectShortcuts = useMemo(() => {
    const projectTypes = uniqBy(projects.data?.map((p) => p.projectType) ?? [], (pt) => pt.idProjectType).sort((a, b) =>
      Comparator.lexicographicalComparison(a.name, b.name)
    );
    if (projects.data) {
      return projectTypes.map((pt) => {
        const sm = projects
          .data!.filter((p) => p.projectType?.idProjectType === pt.idProjectType)
          .filter((p) => !p.isArchived)
          .sort((a, b) => Comparator.lexicographicalComparison(a.name, b.name))
          .map((p) => {
            const projectRoute = `/projects?type=${pt.idProjectType}&active=${p.idProject?.toString()}`;
            const projectSubRouteBase = `/projects/${p.idProject?.toString()}`;

            return (
              <SubMenu
                key={projectRoute}
                title={p.name}
                onTitleClick={() => {
                  navigate(projectRoute);
                }}
              >
                {canUseProjectBundles && (
                  <StyledMenu.Item key={`${projectSubRouteBase}/apps`} icon={<AppIcon />}>
                    <NavLink to={`${projectSubRouteBase}/apps`} className="nav-text">
                      Apps
                    </NavLink>
                  </StyledMenu.Item>
                )}
                {canUseProjectBundles && (
                  <StyledMenu.Item key={`${projectSubRouteBase}/tools`} icon={<ToolIcon />}>
                    <NavLink to={`${projectSubRouteBase}/tools`} className="nav-text">
                      Tools
                    </NavLink>
                  </StyledMenu.Item>
                )}
                {projectPermissions.get(p.idProject!.toString())?.deploymentSvc$getDeploymentPlans && (
                  <StyledMenu.Item key={`${projectSubRouteBase}/deployments/environments`} icon={<DeploymentIcon />}>
                    <NavLink to={`${projectSubRouteBase}/deployments/environments`} className="nav-text">
                      Deployments
                    </NavLink>
                  </StyledMenu.Item>
                )}
              </SubMenu>
            );
          });

        return (
          <SubMenu
            key={pt.idProjectType.toString()}
            icon={<ProjectTypeIcon projectType={pt} />}
            title={pt.name}
            onTitleClick={() => {
              navigate(`/projects?type=${pt.idProjectType}`);
            }}
          >
            {sm}
          </SubMenu>
        );
      });
    }
    return [];
  }, [navigate, projectPermissions, projects.data, canUseProjectBundles]);

  return (
    <>
      {session.state === 'loggedIn' && (
        <StyledMenu mode="horizontal" selectedKeys={selectedKeys} triggerSubMenuAction="hover">
          {showHome && (
            <StyledMenu.Item key="/" icon={<HomeOutlined />} tabIndex={0}>
              <NavLink to="/" className="nav-text">
                Home
              </NavLink>
            </StyledMenu.Item>
          )}
          {canUseProjects && projectShortcuts}
          {(canUseEngineeringPlatform || canUseSoftwarePlatform) && (
            <SubMenu icon={<ToolIcon />} key="platforms" title="Platforms">
              {canUseEngineeringPlatform && (
                <StyledMenu.Item key="/tools" icon={<ToolIcon />} tabIndex={0}>
                  <NavLink to="/tools" className="nav-text">
                    Engineering Platform
                  </NavLink>
                </StyledMenu.Item>
              )}
              {canUseSoftwarePlatform && (
                <StyledMenu.Item key="/apps" icon={<ToolIcon />} tabIndex={0}>
                  <NavLink to="/apps" className="nav-text">
                    Software Platform
                  </NavLink>
                </StyledMenu.Item>
              )}
            </SubMenu>
          )}
          {hasAdminFeatures && (
            <SubMenu icon={<UserSwitchOutlined />} key="pacts-administration" title="PacTS Administration">
              {canAdministrateProjects && (
                <StyledMenu.Item key="/project-member-administration" icon={<UserSwitchOutlined />} tabIndex={0}>
                  <NavLink to="/project-member-administration" className="nav-text">
                    Project Member Administration
                  </NavLink>
                </StyledMenu.Item>
              )}
              {/* TODO: refine to usecase */}
              {canAdministrateUsers && (
                <StyledMenu.Item key="/user-administration" icon={<UserSwitchOutlined />} tabIndex={0}>
                  <NavLink to="/user-administration" className="nav-text">
                    User Administration
                  </NavLink>
                </StyledMenu.Item>
              )}
              {/* TODO: refine */}
              {permissions.all$unrestrictedAdministration && (
                <StyledMenu.Item key="/entity-administration" icon={<DiffOutlined />} tabIndex={0}>
                  <NavLink to="/entity-administration" className="nav-text">
                    Entity Administration
                  </NavLink>
                </StyledMenu.Item>
              )}
              {permissions.all$unrestrictedAdministration && (
                <StyledMenu.Item key="/session-administration" icon={<DiffOutlined />} tabIndex={0}>
                  <NavLink to="/session-administration" className="nav-text">
                    Session Administration
                  </NavLink>
                </StyledMenu.Item>
              )}
              {permissions.pactsFormationSvc$unrestrictedAdministration && (
                <StyledMenu.Item key="/pactsformation" icon={<ApartmentOutlined />} tabIndex={0}>
                  <NavLink to="/pactsformation" className="nav-text">
                    PacTS Formation
                  </NavLink>
                </StyledMenu.Item>
              )}
              {permissions.all$unrestrictedAdministration && (
                <StyledMenu.Item key="/notification-administration" icon={<NotificationOutlined />} tabIndex={0}>
                  <NavLink to="/notification-administration" className="nav-text">
                    Notification Dashboard
                  </NavLink>
                </StyledMenu.Item>
              )}
            </SubMenu>
          )}
        </StyledMenu>
      )}
    </>
  );
};
