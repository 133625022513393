import { Col, Row, Space } from 'antd';
import * as Styled from '../base/Components.styled';
import { useStyles } from '../../../contexts/theme/useStyles';
import { THEMES, useTheme } from '../../../contexts/theme/ThemeProvider';
import { InfoTooltip } from './InfoTooltip';
import { formatDateTime } from './formatDate';


type AuditProps = { audit: { createdBy?: string | null; createdAt?: string | null; updatedBy?: string | null; updatedAt?: string | null } };

export const AuditInformation = ({ audit }: AuditProps) => {
  const [currentTheme] = useTheme();
  const styles = useStyles();
  const tooltipTextColor = currentTheme === THEMES.LIGHT ? styles.colorText : null;

  let resShort: React.ReactNode[] = [];
  let resTooltip: React.ReactNode[] = [];

  const AuditText = (auditTextProps: { prefix: string; timestamp: string; color?: string | null }) => {
    const date = new Date(auditTextProps.timestamp);
    return <Styled.Text color={auditTextProps.color} type="secondary">{`${auditTextProps.prefix} ${formatDateTime(date)}`}</Styled.Text>;
  };

  const AuditTooltipEntry = (auditTooltipEntryProps: { prefix: string; by: string; timestamp: string; color?: string | null }) => {
    return (
      <Space direction="vertical" size={0}>
        <Styled.Text color={auditTooltipEntryProps.color} type="secondary">{`${auditTooltipEntryProps.prefix} by: ${auditTooltipEntryProps.by}`}</Styled.Text>
        <AuditText color={auditTooltipEntryProps.color} prefix={`${auditTooltipEntryProps.prefix} at:`} timestamp={auditTooltipEntryProps.timestamp} />
      </Space>
    );
  };

  if (audit.createdAt && audit.createdBy) {
    resShort.push(<AuditText prefix={`Created:`} timestamp={audit.createdAt} />);
    resTooltip.push(<AuditTooltipEntry color={tooltipTextColor} prefix={`Created`} timestamp={audit.createdAt} by={audit.createdBy} />);
  }
  if (audit.updatedAt && audit.updatedBy && audit.updatedAt !== audit.createdAt) {
    resShort.push(<AuditText prefix={`Updated:`} timestamp={audit.updatedAt} />);
    resTooltip.push(<AuditTooltipEntry color={tooltipTextColor} prefix={`Updated`} timestamp={audit.updatedAt} by={audit.updatedBy} />);
  }

  return resShort.length > 0 ? (
    <Row gutter={[8, 0]}>
      {resShort.map((r, i) => (
        <Col key={i}>{r}</Col>
      ))}
      <Col flex="1">
        <InfoTooltip
          text={
            <Space direction="vertical" size="large">
              {resTooltip}
            </Space>
          }
        />
      </Col>
    </Row>
  ) : null;
};
