import { useMemo } from 'react';
import EventEmitter from 'eventemitter3';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { Configuration, AuthApi, CommunicationApi, RefreshTokenClientIdEnum } from '@pacts/userservice-api';
import { AuthBackend, AuthBackendError } from '../service/authBackend';
import {
  AuthTokenResponse,
  AuthTokenRefreshRequest,
  AuthRedeemRequest,
  AuthRefreshTokenClientIdEnum,
  AuthRefreshTokenProviderEnum,
  OauthDeviceCodeResponse
} from '../domain/auth';

import { SharedAxiosInstance } from '../../shared/sharedAxiosInstance';
import { GlobalState } from '../../../state/globalState';

class RestUserBackend implements AuthBackend {
  private readonly emitter: EventEmitter = new EventEmitter();

  private readonly aApi: AuthApi;

  private readonly cApi: CommunicationApi;

  constructor(
    public readonly config: Configuration,
    instance: AxiosInstance
  ) {
    this.aApi = new AuthApi(config, undefined, instance);
    this.cApi = new CommunicationApi(config, undefined, instance);
  }

  getLoginUri(args: { client: AuthRefreshTokenClientIdEnum; provider: AuthRefreshTokenProviderEnum; redirect: string }): string {
    return `${this.config.basePath}/oauth/authorize?client_id=${args.client}${args.redirect}&provider=${args.provider}`;
  }

  heartbeat(): Promise<void> {
    return this.genericPromise(this.cApi.getHealth());
  }

  async tokenRefresh(req: AuthTokenRefreshRequest): Promise<AuthTokenResponse> {
    // make heartbeat first to ensure stable network connection
    await this.heartbeat();

    let lastError: AuthBackendError;

    // retry token refresh a few times in case of non-user errors
    // this mitigates effects of e.g., ERR_NETWORK_CHANGED
    // leverages leeway time for refreshes
    for (let i = 0; i < 5; i++) {
      try {
        const r = await this.aApi.oauthToken('refresh_token', RefreshTokenClientIdEnum.Pacts, undefined, req.refreshToken, undefined, undefined, {
          timeout: 3000
        });
        return { accessToken: r.data.access_token, refreshToken: r.data.refresh_token };
      } catch (error) {
        const axiosErr = error as AxiosError;
        lastError = new AuthBackendError(axiosErr.message, axiosErr.response?.status || 999, []);
        console.warn(`refreshing token failed (${lastError})`);

        // retry if error is internal or network error
        // chrome uses to have err network changed
        if (lastError.statusCode > 499) {
          const timeout = Math.min(i > 0 ? 2 ** i * 200 : 100, 5 * 1000);
          console.warn(`retry token refresh attempt ${i + 1}, delay by ${timeout}ms`);
          await new Promise<void>((resolve) => setTimeout(resolve, timeout));
          continue;
        }

        throw lastError;
      }
    }

    throw lastError!;
  }

  tokenRedeem(req: AuthRedeemRequest): Promise<AuthTokenResponse> {
    return new Promise((resolve, reject) => {
      this.aApi
        .oauthToken('authorization_code', RefreshTokenClientIdEnum.Pacts, req.code)
        .then((r) => {
          resolve({ accessToken: r.data.access_token, refreshToken: r.data.refresh_token });
        })
        .catch(this.errorHandler(reject).bind(this));
    });
  }

  startDeviceFlow(): Promise<OauthDeviceCodeResponse> {
    return this.genericPromise(this.aApi.oauthDeviceCode(RefreshTokenClientIdEnum.External, 'azuread'));
  }

  pollDeviceFlow(deviceCode: string): Promise<AuthTokenResponse> {
    return new Promise((resolve, reject) => {
      this.aApi
        .oauthToken('urn:ietf:params:oauth:grant-type:device_code', RefreshTokenClientIdEnum.External, undefined, undefined, deviceCode, 'azuread')
        .then((r) => {
          resolve({ accessToken: r.data.access_token, refreshToken: r.data.refresh_token });
        })
        // emit raw error here, since this is evaluated further
        .catch(reject);
    });
  }

  onError(handler: (error: AuthBackendError) => any): void {
    this.emitter.on('error', handler);
  }

  private errorHandler(rejector: (error: AuthBackendError) => any): (error: AxiosError) => any {
    return (error: AxiosError) => {
      const err = new AuthBackendError(error.message, error.response?.status || 999, []);
      this.emitter.emit('error', err);
      rejector(err);
    };
  }

  private genericPromise<T>(promise: Promise<AxiosResponse<T>>) {
    return new Promise<T>((rs, rj) => {
      promise.then((r) => rs(r.data)).catch(this.errorHandler(rj).bind(this));
    });
  }
}

export const useRestAuthBacked = (state: GlobalState) => {
  const backend = useMemo(
    () => new RestUserBackend(new Configuration({ basePath: state.userServiceBasePath }), SharedAxiosInstance.instance()),
    [state.userServiceBasePath]
  );
  return backend;
};
