import * as antd from 'antd';
import { Rule } from 'antd/es/form';
import { FileSelection, UploadAnt } from './AntBlobUpload';
import { useRef, useState } from 'react';

const isArtifactory = (link: string) => {
  return link.includes('artifactory.siemens-energy.com');
};

export const BlobUploadFormItem = (props: {
  label: string;
  field: (string | number)[];
  relativeFieldInList?: (string | number)[];
  labelAlign?: 'left' | 'right';
  rules?: Rule[];
  optional?: boolean;
  uploadDisabled?: boolean;
  allowArtifactoryLinks?: boolean;
  shouldUpdate: (prevValuse: any, currentValues: any) => boolean;
}) => {
  const [forceRefresh, setForceRefresh] = useState(false);
  const [currentValue, setCurrentValue] = useState<FileSelection | undefined>();
  const initialValue = useRef<string | null>(null);
  return (
    <antd.Form.Item
      label={props.label}
      labelAlign={props.labelAlign}
      required={!props.optional}
      shouldUpdate={(prev, next) => {
        if (forceRefresh) {
          setForceRefresh(false);
          return true;
        }
        return props.shouldUpdate(prev, next);
      }}
      style={{ marginTop: 16 }}
    >
      {({ getFieldValue, setFieldValue, getFieldError, validateFields }) => {
        const validateAndForceRefresh = () => {
          validateFields([props.field])
            .catch(() => {
              /* */
            })
            .finally(() => {
              setForceRefresh(true);
            });
        };

        if (initialValue.current === null) {
          initialValue.current = getFieldValue(props.field) ?? null;
        }

        const rules: Rule[] = [
          ...(props.rules || []),
          {
            validator: () => {
              // Here, the required rule should kick in
              if (!currentValue?.link) return Promise.resolve();

              const currentValueIsArtifactoryLink = isArtifactory(currentValue.link);

              // If artifactory links are not allowed - block (e.g., creation)
              if (currentValueIsArtifactoryLink && !props.allowArtifactoryLinks) {
                return Promise.reject(new Error('Artifactory links are not allowed for security reasons. Please contact support if required.'));
              }

              // If artifactory links are allowed (e.g., for updating), make sure they are not changed as the backend does not allow this
              if (currentValueIsArtifactoryLink && currentValue.link !== initialValue.current) {
                return Promise.reject(new Error('Artifactory links cannot be changed for security reasons. Please contact support if required.'));
              }

              // Reject if invalid extension
              const extension = currentValue?.file?.extension || currentValue?.link.split('.').pop() || '';
              if (!extension) return Promise.reject(new Error('Invalid file extension.'));

              return Promise.resolve();
            }
          }
        ];

        return (
          <>
            <UploadAnt
              initialValue={getFieldValue(props.field)}
              uploadDisabled={props.uploadDisabled}
              onChange={(v) => {
                setCurrentValue(v);
                setFieldValue(props.field, v.link);
                // delay validation by one render cycle to make sure
                // the prior state changes have been propagated
                setImmediate(() => {
                  validateAndForceRefresh();
                });
              }}
              showError={getFieldError(props.field).length > 0}
            />
            <antd.Form.Item noStyle name={props.relativeFieldInList || props.field} rules={rules} />
          </>
        );
      }}
    </antd.Form.Item>
  );
};
