import { InstalledText } from './InstalledText';

import type { ComponentForDevices } from '../helper/deploymentTypes';


type Props = {
  component: ComponentForDevices;
};

export const InstalledStatus = (props: Props) => {
	const { component } = props;

	const isAllInstalled = component.allInstalled;
	const installed = component.targets.filter((t) => t.isInstalled);
	const label = `${installed.length}/${component.targets.length} installed`;

	return <InstalledText isSuccess={isAllInstalled}>{label}</InstalledText>;
}