import { useState } from 'react';
import { Button, Space, Typography } from 'antd';
import { DeploymentPlan, DeploymentPlanPatchDeploymentDeployments } from '../../../api';
import { ScrollDialog } from '../../layout/components/ScrollDialog';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';
import { useUpdateMultipleDeploymentPlanDeployments } from '../hooks/useUpdateMultipleDeploymentPlanDeployments';
import { groupBy } from 'lodash';
import { ComponentForDevice } from '../helper/deploymentTypes';
import { DescriptionTextAreaInput } from '../../../contexts/shared/components/DescriptionTextAreaInput';
import { PermanentActionWarningLabel } from '../../../contexts/shared/components/PermanentActionWarningLabel';

export const UpdateDeploymentPlanMultiDeploymentButton = (props: {
  projectId: number;
  envId: string;
  plan: DeploymentPlan;
  selectedRows: ComponentForDevice[];
  onReset?: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [notes, setNotes] = useState('');
  const updateMultipleDeployments = useUpdateMultipleDeploymentPlanDeployments();
  const componentName = props.selectedRows[0]?.component.name || 'unknown';
  const componentVersion = props.selectedRows[0]?.component?.version || 'unknown';

  const hasPermissions = usePermissions({
    projectId: props.projectId.toString(),
    environmentId: props.envId,
    deploymentPlanId: props.plan.id
  }).deploymentSvc$patchDeploymentPlan;

  if (!hasPermissions) return null;

  const deviceActions = groupBy(props.selectedRows, (e) => e.device.id);

  const deployments: DeploymentPlanPatchDeploymentDeployments[] = Object.keys(deviceActions)
    .map((deviceId) => {
      const deviceSw = deviceActions[deviceId];
      if (deviceSw.length < 1) return undefined;
      const ret: DeploymentPlanPatchDeploymentDeployments = {
        device: { id: deviceId },
        components: deviceSw.map((d) => d.component)
      };
      return ret;
    })
    .filter(Boolean) as DeploymentPlanPatchDeploymentDeployments[];

  return (
    <>
      <ScrollDialog
        title="Confirm Installation"
        width={512}
        confirmLoading={isLoading}
        open={isVisible}
        destroyOnClose
        cancelButtonProps={{ disabled: isLoading }}
        onOk={() => {
          setIsLoading(true);
          updateMultipleDeployments
            .mutateAsync([
              {
                action: 'install',
                mvccId: props.plan.mvccId,
                notes,
                deployments
              },
              props.plan.id,
              props.projectId,
              props.envId
            ])
            .then(() => {
              props.onReset?.();
              setNotes('');
              setIsVisible(false);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
        onCancel={() => {
          setIsVisible(false);
        }}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <div>
            <Typography.Text>Please confirm the installation of </Typography.Text>
            <Typography.Text strong type="warning">
              {componentName}
            </Typography.Text>
            <Typography.Text> version </Typography.Text>
            <Typography.Text strong type="warning">
              {componentVersion}
            </Typography.Text>
            <Typography.Text> to </Typography.Text>
            <Typography.Text strong type="warning">
              {props.selectedRows.length}
            </Typography.Text>
            <Typography.Text> selected devices.</Typography.Text>
          </div>
          <DescriptionTextAreaInput value={notes} onChange={(e) => setNotes(e.target.value)} placeholder="Installation notes" />
          <PermanentActionWarningLabel />
        </Space>
      </ScrollDialog>
      <Button type="primary" loading={isLoading} onClick={() => setIsVisible(true)} disabled={!props.selectedRows.length}>
        Set as installed ({props.selectedRows.length})
      </Button>
    </>
  );
};
