import { uniq } from 'lodash';
import { useMemo, useState } from 'react';
import { Skeleton, Space, TableColumnsType, Typography } from 'antd';
import { Comparator } from '../../../domain';
import Table from '../../../contexts/shared/components/Table/Table';
import { ResourceError } from '../../../api';
import { useAllMemberships } from '../hooks/useMemberships';
import MembershipDetails from './MembershipDetails';
import styled from 'styled-components';
import { valueFromObjectPath } from '../../../contexts/reports';
import { useDebounce } from '../../../contexts/shared/hooks/useDebounce';
import { useTableSearch } from '../../../contexts/shared/components/TableSearch';
import { useTableFilter } from '../../../contexts/shared/components/TableFilter';
import { StyledModal } from '../../../contexts/shared/components/StyledModal';

export interface DataType {
  key: React.Key;
  status: string;
}

const FullWidthSpace = styled(Space)`
  width: 100%;
`;

export const AllMembershipResources = () => {
  const membershipResources = useAllMemberships();
  const isLoading = useDebounce(membershipResources.isLoading || membershipResources.isFetchingNextPage, 10, true);

  const statuses: string[] = uniq(membershipResources.data?.map((membership) => membership?.status) || []);
  const [errorModal, setErrorModal] = useState<null | ResourceError>(null);
  const columns: TableColumnsType<DataType> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ...useTableSearch({
        searchParamId: 'id',
        searchValueProvider: valueFromObjectPath(['id'])
      })
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ...useTableFilter({
        searchParamId: 'status',
        values: statuses.map((v: string) => ({ text: v, value: v })),
        onFilter(data, filterVal) {
          const value = valueFromObjectPath<string>(['status'])(data);
          return value.includes(filterVal);
        },
        key: statuses.join('+')
      })
    },
    {
      title: 'User GID',
      dataIndex: 'userGid',
      key: 'userGid'
    }
  ];
  const data: DataType[] = useMemo(
    () =>
      membershipResources.data
        ?.sort((a, b) => Comparator.lexicographicalComparison(a.id, b.id))
        .map((membership) => {
          return {
            key: membership.id,
            id: membership.id,
            userGid: membership.userGid,
            status: membership.status
          };
        }) || [],
    [membershipResources.data]
  );

  return (
    <FullWidthSpace direction="vertical">
      <StyledModal title={errorModal?.summary} open={errorModal != null} destroyOnClose onCancel={() => setErrorModal(null)} onOk={() => setErrorModal(null)}>
        <Typography.Paragraph>Error Code: {errorModal?.errorCode}</Typography.Paragraph>
        <Typography.Paragraph>Internal Error code: {errorModal?.technicalDetails?.internalErrorCode}</Typography.Paragraph>
        <Typography.Paragraph>Summary: {errorModal?.technicalDetails?.description}</Typography.Paragraph>
      </StyledModal>
      <Skeleton loading={isLoading} active>
        <Table
          columns={columns}
          dataSource={data}
          expandable={{
            rowExpandable: () => true,
            expandedRowRender: (project: DataType) => <MembershipDetails onSetErrorModal={setErrorModal} project={project} />
          }}
        />
      </Skeleton>
    </FullWidthSpace>
  );
};
