import { SoftwareAppVersion, ToolVersion } from '../../../../../api/engineering/domain/types';
import { DUMMY_CHECKSUM } from '../constants';

export const isValidChecksum = (sha256?: string) => {
  if (!sha256) return false;
  if (!/^[a-f0-9]{64}$/.test(sha256)) return false;
  if (sha256 === DUMMY_CHECKSUM) return false;

  return true;
};

const isValidArtifactoryDownloadLink = (link?: string) => {
  if (!link) return false;
  if (!link.includes('artifactory.siemens-energy.com')) return false;
  if (!link.includes('/artifactory/')) return false;
  if (!link.startsWith('https://')) return false;

  return true;
};

export const useArtifactoryLinkPreFlight = (version: ToolVersion | SoftwareAppVersion | null) => {
  if (!version) {
    return false;
  }

  if (isValidArtifactoryDownloadLink((version as ToolVersion).downloadLink) && isValidChecksum((version as ToolVersion).sha256)) {
    return true;
  }

  if ((version as SoftwareAppVersion).targets?.some((t) => isValidArtifactoryDownloadLink(t.downloadLink) && isValidChecksum(t.sha256))) {
    return true;
  }

  return false;
};

export const useValidTargets = (version: SoftwareAppVersion) => {
  return version.targets.filter((t) => isValidArtifactoryDownloadLink(t.downloadLink) && isValidChecksum(t.sha256));
};
