import { Row, Col, Divider, Typography } from 'antd';
import { MarkdownPreview } from './MarkdownPreview';
import { AuditInformation } from './AuditInformation';
import { Sha256 } from './Sha256';
import { SyncingTag } from './SyncingTag';
import { usePermissions } from '../../../contexts/session';
import { SoftwareAppVersion, ToolVersion } from '../../../api';
import styled from 'styled-components';

const MarginDivider = styled(Divider)`
  margin-top: 12px;
  margin-bottom: 8px;
`;

export const ComponentVersionListEntry = (props: { record: ToolVersion | SoftwareAppVersion; showAudit: boolean }) => {
  const permissions = usePermissions();

  const id = (props.record as ToolVersion)?.idToolVersion ?? (props.record as SoftwareAppVersion)?.idSoftwareAppVersion;

  const checksums: { sha256?: string; target?: string }[] | undefined = !!(props.record as ToolVersion)?.sha256
    ? [{ sha256: (props.record as ToolVersion)?.sha256 }]
    : (props.record as SoftwareAppVersion)?.targets?.map((t) => ({ sha256: t.sha256, target: t.target.name }));

  return (
    <>
      <MarkdownPreview content={props.record.releaseNotes || ''} title={`Release Notes (${props.record.version})`} />
      <Row gutter={[8, 5]}>
        <Col span={2}>
          <MarginDivider />
        </Col>
        {props.record.state !== 'consistent' && (
          <Col span={24}>
            <SyncingTag />
          </Col>
        )}
        <Col span={24}>
          <Sha256 checksums={checksums ?? []} />
        </Col>
        {props.showAudit && (
          <Col>
            <AuditInformation audit={props.record} />
          </Col>
        )}
        <Col>
          <Typography.Text type="secondary">{permissions.all$unrestrictedAdministration && `ID: ${id}`}</Typography.Text>
        </Col>
      </Row>
    </>
  );
};
