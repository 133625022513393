import { Button, Divider, Input, Space, Tabs, Typography } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import ReactJson from 'react-json-view';
import { jwtDecode } from 'jwt-decode';
import dayjs from 'dayjs';
import { ReactQueryDevtoolsPanel } from '@tanstack/react-query-devtools';
import { usePermissions, useSession } from '../../session';
import { PacTSFormationHeartbeat } from '../../pactsformation/components/PactsformationHeartbeat';
import { ScrollDialog } from '../../layout/components/ScrollDialog';
import { useTick } from '../../shared/hooks/useTick';
import styled from 'styled-components';
import { PacTSContext } from '../../../state/store';
import { BasePathActions } from '../../../state/actions/basePathActions';
import { TokenActions } from '../../../state/actions/tokenActions';
import { Copyable } from '../../../contexts/token/Copyable';
import { NotificationHeartbeat, SubscriptionsList } from '../../../contexts/notifications/components';
import { formatDate } from '../../../contexts/shared/components/formatDate';

const { Title } = Typography;

const StyledCode = styled.code`
  color: ${({ theme }) => theme.colorWhite};
`;

const StyledButton = styled(Button)`
  font-size: 10px;
  padding: 0;
`;

const VERSION_LOCK = import.meta.env.VITE_VERSION_LOCK;

export const DevPanel = () => {
  const [projectId, setProjectId] = useState('1');
  const permissions = usePermissions();
  const projectPermissions = usePermissions({ projectId });
  const [state, dispatch] = useContext(PacTSContext);
  const session = useSession(state);
  const [devVisible, setDevVisible] = useState(false);
  const [stateCopy, setStateCopy] = useState({ ...state });
  const version = import.meta.env.VITE_PACTS_VERSION || 'dev';
  const [tick] = useTick();

  const versionLock = useMemo(() => {
    try {
      return JSON.parse(atob(VERSION_LOCK));
    } catch (error) {
      console.error(error);
    }
    return {};
  }, []);

  const loggedIn = session.state === 'loggedIn';

  const accessTokenMeta = useMemo(() => {
    try {
      const token = jwtDecode(state.token.token) as any;
      token.iat_parsed = formatDate(dayjs.unix(token.iat).toDate());
      token.exp_parsed = formatDate(dayjs.unix(token.exp).toDate());
      token.exp_in = `${token.exp - tick}s`;
      return token;
    } catch (error) {
      return error;
    }
  }, [state.token, tick]);
  const refreshTokenMeta = useMemo(() => {
    try {
      const token = jwtDecode(state.token.refreshToken) as any;
      token.iat_parsed = formatDate(dayjs.unix(token.iat).toDate());
      token.exp_parsed = formatDate(dayjs.unix(token.exp).toDate());
      return token;
    } catch (error) {
      return error;
    }
  }, [state.token]);

  useEffect(() => {
    setStateCopy({ ...state });
  }, [state]);
  return (
    <>
      <StyledButton
        type={'text'}
        disabled={!loggedIn}
        size="small"
        onClick={() => {
          if (loggedIn) {
            setDevVisible(!devVisible);
          }
        }}
      >
        <StyledCode>[{version}]</StyledCode>
      </StyledButton>

      <ScrollDialog width={1650} title="DevPanel" open={devVisible} footer={[]} onCancel={() => setDevVisible(false)}>
        <Tabs defaultActiveKey="state">
          <Tabs.TabPane tab="Token" key="state">
            <Space direction="vertical">
              <Title level={4}>State</Title>
              <Space direction="horizontal">
                <Input
                  addonBefore={<div style={{ width: 90, textAlign: 'left' }}>Base Path</div>}
                  style={{ width: 900 }}
                  value={stateCopy.engineeringServicePath}
                  onChange={(val) => setStateCopy({ ...stateCopy, engineeringServicePath: val.target.value })}
                />
                <Button
                  onClick={() =>
                    dispatch({
                      type: BasePathActions.SET_BASEPATH,
                      payload: stateCopy.engineeringServicePath
                    })
                  }
                >
                  Apply
                </Button>
              </Space>
              <Space direction="horizontal">
                <Input
                  addonBefore={<div style={{ width: 90, textAlign: 'left' }}>Token</div>}
                  style={{ width: 900 }}
                  value={stateCopy.token.token}
                  allowClear
                  onChange={(val) => setStateCopy({ ...stateCopy, token: { ...stateCopy.token, token: val.target.value } })}
                />
                <Button
                  onClick={() =>
                    dispatch({
                      type: TokenActions.SET_TOKEN,
                      payload: stateCopy.token
                    })
                  }
                >
                  Apply
                </Button>
              </Space>
              <Space direction="horizontal">
                <Input
                  addonBefore={<div style={{ width: 90, textAlign: 'left' }}>Refresh Token</div>}
                  style={{ width: 900 }}
                  value={stateCopy.token.refreshToken}
                  allowClear
                  onChange={(val) => setStateCopy({ ...stateCopy, token: { ...stateCopy.token, refreshToken: val.target.value } })}
                />
                <Button
                  onClick={() =>
                    dispatch({
                      type: TokenActions.SET_TOKEN,
                      payload: stateCopy.token
                    })
                  }
                >
                  Apply
                </Button>
              </Space>
              <Divider />
              <Title level={4}>Token</Title>
              <ReactJson
                theme="harmonic"
                src={accessTokenMeta}
                collapseStringsAfterLength={120}
                groupArraysAfterLength={100}
                shouldCollapse={(field) => field.type === 'array'}
              />
              <Divider />
              <Title level={4}>Refresh Token Meta</Title>
              <ReactJson
                theme="harmonic"
                src={refreshTokenMeta}
                collapseStringsAfterLength={80}
                groupArraysAfterLength={100}
                shouldCollapse={(field) => field.type === 'array'}
              />
            </Space>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Version" key="version">
            <Space direction="vertical" style={{ width: '100%' }}>
              <Copyable content={JSON.stringify(versionLock, null, 2)} />
              <ReactJson theme="harmonic" src={versionLock} collapseStringsAfterLength={80} />
            </Space>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Permissions" key="permissions">
            <Space direction="vertical" style={{ width: '100%' }}>
              <Divider />
              <Title level={4}>Permissions</Title>
              <ReactJson theme="harmonic" src={permissions} collapseStringsAfterLength={80} />
              <Divider />
              <Title level={4}>Project Permissions</Title>
              <Input placeholder="Project Id" onChange={(e) => setProjectId(e.target.value)} value={projectId} />
              <ReactJson theme="harmonic" src={projectPermissions} collapseStringsAfterLength={80} />
            </Space>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Data" key="data">
            <ReactQueryDevtoolsPanel
              isOpen
              setIsOpen={() => {
                /* */
              }}
              onDragStart={() => {
                /* */
              }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <Space>
                Subscriptions <NotificationHeartbeat />
                <PacTSFormationHeartbeat />
              </Space>
            }
            key="sub"
          >
            <SubscriptionsList />
          </Tabs.TabPane>
        </Tabs>
      </ScrollDialog>
    </>
  );
};
